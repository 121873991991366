.landingPageTargi {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
  &__top {
    display: flex;
    flex: 1;
    .landingPageTargi__left {
      flex: 1;
    }
    .landingPageTargi__background-image {
      width: 100%;
    }

    .landingPageTargi__contact-form-section {
      width: 372px;
      .contact-form-section-container {
        padding: 24px;
        box-shadow: none;
      }
    }

    .landingPageTargi__bottom-text{
      margin: 20px 28px 28px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .landingPageTargi {
    .landingPageTargi__top {
      flex-direction: column;

      .landingPageTargi__contact-form-section {
        width: 320px;
      }
    }
  }
}
