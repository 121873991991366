@import "../../../assets/styles/variables";

.contact-section-container {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #ffffff;

  .section-title {
    text-align: center;
    padding-top: 48px;
  }

  .contact-section-paper {
    display: flex;
    padding: 32px 143px 48px;
    justify-content: flex-start;
  }

  &.bg-white {
    &,
    .contact-section-paper,
    .information-tile-container {
      background-color: white;
    }
  }

  &.bg-white-tile-only {
    background-color: #f6f6f6;

    .contact-section-paper {
      div[data-component="Tile"] {
        background-color: white;
      }
    }
  }

  &.bg-gray {
    &,
    .contact-section-paper,
    .information-tile-container {
      background-color: #f6f6f6;
    }
  }

  &.list-main {
    .section-title {
      padding-top: 0;
      padding-bottom: 20px;
    }

    .contact-section-paper {
      justify-content: space-between;
      padding: 0;
      gap: 25px;

      .information-tile-container > div {
        box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.08);
        height: 100%;
      }

      & > div {
        width: 100%;
      }
    }
  }

  &.details {
    .section-title {
      padding-top: 0;
      padding-bottom: 0;
    }

    .contact-section-paper {
      justify-content: space-between;
      padding: 0;

      & > div {
        width: calc(50% - 12px);
      }
    }
  }
}

.questions-section {
  padding: 0 $paddingDesktop;
}

@media screen and (max-width: 1023px) {
  .contact-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .section-title {
      width: 100%;
      padding: 10px 16px 16px;
      font-weight: 600;
    }

    &.details {
      .contact-section-paper {
        & > div:first-of-type {
          min-width: unset;
          width: 100%;
        }
      }
    }

    .contact-section-paper {
      width: 100%;
      padding: 16px 16px 48px 16px;
      display: flex;
      flex-direction: column;

      .information-tile-container {
        width: 100%;
        padding-left: 0;
        margin: 0;
      }
    }

    &.details {
      .contact-section-paper {
        & > div:nth-child(n) {
          min-width: unset;
          width: 100%;
        }
      }
    }

    &.list-main {
      .section-title {
        text-align: center;
        padding: 8px 16px 0;
        width: 80%;
      }

      & > .section-title {
        width: 270px;
        display: inline-block;
        text-align: center;
        margin: auto;
        padding-bottom: 0;
        padding-top: 8px;
      }

      .contact-section-paper {
        gap: 16px;

        .information-tile-container {
          min-width: unset;
          > div {
            box-shadow: none;
          }
        }
      }
    }
  }

  .questions-section {
    padding: 0 16px;
  }
}
