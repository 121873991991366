@-webkit-keyframes circleAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circleAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinning-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #d8d8d8AA;
  border-right: 1.1em solid #d8d8d8AA;
  border-bottom: 1.1em solid #d8d8d8AA;
  border-left: 1.1em solid #545454;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circleAnimation 1.1s infinite linear;
  animation: circleAnimation 1.1s infinite linear;

  &.small {
    margin: 20px auto;

    &, &:after {
      border-radius: 50%;
      width: 4em;
      height: 4em;
    }
  }

  &.is-absolute {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 25%;
    bottom: 25%;
  }

  &, &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
}
