@import "../../../assets/styles/variables";

.fast-buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 52px;
  left: 24px;
  z-index: $z-index-fast-buttons;
  gap: 24px;

  &.hide {
    display: none;
  } 

  .fast-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    &::before {
      content: "";
      position: absolute;
      width: 64px;
      height: 64px;
      opacity: 0.5;
      border-radius: 50%;
      border: 12px solid;
    }

    &.red::before {
      border-color: rgba(174, 0, 0, 0.5);
    }

    &.blue::before {
      border-color: rgba(0, 101, 177, 0.5);
    }

    &:hover {
      &::before {
        opacity: 0.6;
      }
    }
  }
}

.fastcall-widget {
  display: none !important;
}

@media (max-width: 1023px) {
  .fast-buttons {
    position: relative;
    bottom: 0;
    left: 0;
    flex-direction: row;
    justify-content: center;
  }
}
