@import "../../../assets/functions/get-color";
@import "../../../assets/styles/variables";

.details-page-container {
  position: relative;

  & > .background {
    opacity: 0.6;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    max-height: 600px;
    background-color: light-mode-property("color-wildSandGray");

    &.without-opacity {
      // TODO - I had to create !important rule cuz of react rendering error (possible rendering errors while putting everything as inline background)
      background-size: cover !important;
    }

    &.default {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

    }
  }
  .main-pros-section-container{
    margin-top: 12px;
  }
  
  .lower-container {
    .mleasing-main-container {
      .mobile {
        display: none;
      }

      .desktop {
        display: flex;
      }
    }
  }

  &.white {
    & > .background,
    & {
      background-color: light-mode-property("primary-background");
    }
  }

  &.dark {
    & > .background,
    & {
      background-color: dark-mode-property("primary-background");
    }

    & > .upper-container,
    & > .lower-container {
      .mleasing-main-container {
        &.bg-white {
          background-color: dark-mode-property("color-zeus-black-ui-30");

          .cs-container {
            background-color: transparent;
          }

          .characteristic-features-list-container {
            .list-container {
              li {
                span {
                  color: dark-mode-property("color-solid-white");
                }
              }
            }
          }
        }

        &.bg-gray {
          background-color: dark-mode-property("color-solid-black");
        }

        .contact-section-container {
          background-color: transparent;

          .contact-section-paper {
            background-color: transparent;

            .information-tile-container {
              background-color: transparent;

              div[data-component="Tile"] {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .contact-section-container .a[data-component="Tile"],
  div[data-component="Tile"] {
    background-color: transparent;
  }

  & > .upper-container {
    display: flex;
    flex-wrap: wrap;
    background-repeat: no-repeat;
    padding-top: 16px;
    position: relative;

    .sticky {
      position: sticky;
      top: 80px;
      height: fit-content;
      z-index: 2;
    }

    .basic-details-information-container {

      .title-container {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }

      .information-tile-container.general {
        height: 170px;
      }
    }

    .characteristic-features-list-container {
      width: 100%;
    }

    .calc-result-container {
      width: 100%;
      padding: 24px 0;

      & > div:first-of-type {
        & > div {
          & > div {
            //align-items: baseline;
          }
        }
      }

      & > div {
        width: 100%;
        margin: auto;
      }
    }

    .availability-information-container {
      display: flex;
      margin-bottom: 40px;

      .characteristic-features-list-container {
        margin-top: 0px;
        margin-bottom: 0px;
        .list-container {
          padding-bottom: 0px;
        }
      }
    }
  }

  .car-details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;

    .car-details-data {
      display: flex;

      &:first-child {
        flex-wrap: wrap;
      }
    }

    .car-details-tags {
      display: flex;
      gap: 8px;
    }
  }

  .content-container {
    .top-buttons {
      position: absolute;
      top: 24px;
      right: 16px;
      z-index: 11;
      padding: 8px;
      border-radius: 24px;
      background: #6E6E6E;
    }
    .top-buttons--hide-left-border-radius{
      padding-left: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .button-favorite {
      margin-left: 6px;
    }

  }

  .my-electric-section {
    background-color: light-mode-property("secondary-background");
    padding: 62px 16px;

    .wrapper {
      max-width: 866px;
      width: 66%;
    }
  }

  .promo-labels{
    position: absolute;
    z-index: 1;
    margin: 12px;
  }

  .seo-section-container {
    padding: 0 32px;
    .seo-section {
      padding: 80px 0;
      h2 {
        font-size: 40px;
        font-weight: 600;
        text-align: center;
      }

      p:not(.seo-section__header) {
        margin: 8px 0 16px;
      }
      &__header {
        font-size: 20px;
        margin: 20px 0 8px;
        font-weight: 600;
      }

      ul {
        li {
          list-style-type: square;
          margin-bottom: 16px;
          &::marker {
            font-size: 24px;
            color: #0065b1;
          }
        }
      }
    }
  }
  .promo-banner-container--mobile {
    display: none;
  }
}

@media screen and (max-width: 1580px) {
  .details-content-container {
    .mleasing-main-container {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1420px) {
  .details-page-container {
    & > .upper-container {
      .characteristic-features-list-container {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .details-page-container {
    & > .background {
      max-height: 940px;
    }

    & > .upper-container {
      background-size: cover;
      
      & .sticky {
        position: inherit;
        top: 0;
      }

      .basic-details-information-container {
        padding-left: 16px;
        padding-right: 16px;

        .car-details-container {
          flex-wrap: wrap;
          padding: 8px 0;
          gap: 8px;
        }

        .notification-container{
          display: flex;
          gap: 8px;
          padding: 8px 0;
        }

        .mobile-header{
          display: flex;
          flex-direction: column;
          padding-bottom: 16px;
        }
      }

      .left-wing {
        padding-bottom: 32px;

        .calc-result-container {
          display: flex;
          flex-direction: column;

          .reservation-info-container {
            background-color: light-mode-property("primary-background");
            padding: 16px 0 24px;
            display: block;

            &.dark {
              background-color: transparent;
            }

            .promoted-offer-sticker {
              padding: 0;
            }

            .tiles-wrapper {
              display: flex;
              flex-direction: column;

              & > div {
                width: 100%;
                display: flex;
                flex-direction: column;

                &:first-of-type {
                  margin-bottom: 16px;
                }
              }
            }
          }

          .launch-preview-box {
            display: block;
            padding: 0;

            &.dark {
              background-color: transparent;
            }
          }
        }
      }

      .characteristic-features-list-container {
        width: 100%;

        .list-container {
          ul {
            margin-bottom: 0;
          }
        }
      }
    }

    & > .lower-container {
      .buttons-container {
        display: flex;
        justify-content: center;
      }
      .mleasing-main-container {
        .car-container {
          width: 100%;
          .paginator {
            &.dark {
              background-color: #201c17;
            }
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .paginator-steps-container {
              display: flex;

              .paginator-step {
                width: 6px;
                height: 6px;
                background-color: #0065b1;
                border: none;
                border-radius: 0;
                opacity: 0.3;
                outline: none;
                padding: 0;
                cursor: pointer;

                &:not(:last-of-type) {
                  margin-right: 8px;
                }

                &.active {
                  width: 18px;
                  opacity: 1;
                }
              }
            }
          }
        }
        .desktop {
          display: none;
        }

        .mobile {
          display: flex;
          width: 100vw;
          a {
            padding: 0;
            margin: 0;
          }
          .small {
            width: 100%;
          }
        }
      }
    }

    .my-electric-section {
      padding: 48px 16px;
      
      .wrapper {
        max-width: initial;
        width: 100%;

        a {
          display: flex;
          width: min-content;
          margin: 0 auto;

          button {
            min-width: 240px;
          }
        }
      }

      &__title {
        margin-bottom: 24px;
      }
    }

    .content-container {
      padding: 0 16px;
      margin: 16px 0 24px;
      display: flex;
      justify-content: space-between;

      .button-copy-link,
      .button-favorite {
        position: absolute;
        z-index: 10;
      }
  
      .button-favorite {
        right: 72px;
        top: 32px;
      }
  
      .button-copy-link {
        right: 32px;
        top: 32px;
      }


      .top-buttons {
        position: static;
        display: flex;
        gap: 8px;
        z-index: 0;
        background: inherit;
      }

      .button-copy-link,
      .button-favorite,
      .special-promotions {
        position: unset;
      }

      .icon-button {
        border-color: #D8D8D8;

        &.button-transparent {
          svg {
            path {
              stroke: #0065B1;
            }
          }
        }
      }

      .button-favorite {
        &.active,
        &:active {
          border-color: #0065B1;

          &.button-transparent {
            svg {
              path {
                stroke: #FFFFFF;
              }
            }
          }
        }
      }
    }
    .seo-section-container {
      .seo-section {
        h2 {
          line-height: 40px;
          font-size: 32px;
        }
      }
    }
    .promo-banner-container--mobile {
      display: block;
      margin: 0 16px;
    }
  }
}

@media (max-width: 520px) {
  .details-page-container {
    & > .upper-container {
      .left-wing {
        padding-bottom: 24px;
        .availability-information-container {
          flex-wrap: wrap;
          margin-bottom: 0;
        }
      }
    }
  }
}
