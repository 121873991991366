@import "../../../assets/styles/variables";

.filters {
  padding: 24px $paddingDesktop;
  background: #ffffff;

  &__container {
    max-width: $containerWidth;
    margin: auto;
    display: flex;
    flex-direction: column;

    .filters__top-section {
      display: flex;
      justify-content: space-between;
    }

    .filters__chips {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      margin: 20px 0;
      .chip-container {
        order: 1;
        &.active {
          order: 0;
        }
      }
    }

    .filters__sorting-section {
      display: flex;
      gap: 24px;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .filters {
    padding: 16px $paddingMobile 15px $paddingMobile;

    &__container {
      gap: 12px;
    }

    .filters__top-section {
      [data-component="Switch"] {
        display: none;
      }
    }

    .filters__chips {
      flex-wrap: nowrap;
      height: 32px;
      overflow-x: scroll;
      overflow-y: hidden;
      order: 1;
      margin: 0;

      [data-component="Chip"] {
        overflow: visible;
      }
    }

    .filters__sorting-section {
      justify-content: flex-start;
      padding: 12px 0;
      margin-top: 0;
    }
  }
}

.clear-text {
  display: inline-block;
  width: 100%;
  padding: 0 8px 8px 0;
  text-decoration: underline;
  color: #0065b1;
  font-weight: 600;
  text-align: right;
  cursor: pointer;
}

.textfield-range {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
}

.textfield-dropdown-list {
  display: flex;
  .textfield-dropdown {
    width: 50%;
  }
}

.model-checkbox {
  padding-left: 36px;
}
[data-component="Grow"] {
  max-height: 700px;
  background: #f6f6f6;
}
