.search-input {
  width: 100%;
  position: relative;
  &__input {
    width: 100%;
    height: 40px;
    border: none;
    border-left: 2px solid #0065b1;
    font-size: 20px;
    font-weight: 600;
    outline: none;
    padding-left: 8px;
    background-color: #ffffff;
    &:focus {
      border: none;
      border-left: 2px solid #0065b1;
    }
  }


  .dropdown-field {
    border-radius: 0px 0px 8px 8px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 16px 48px 0px, rgba(0, 0, 0, 0.04) 1px 1px 1px 0px;
    position: absolute;
    width: 100%;
    background-color: #f6f6f6;
    z-index: 9;
    &--hide {
      display: none;
    }
    &__button {
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
    }
    .selected-items-list {
      display: flex;
      flex-wrap: wrap;
      padding: 16px;
      & > button {
        margin: 8px 0 0 8px;
      }
    }
    .items-list {
      max-height: 300px;
      overflow-y: scroll;
      margin: 0;
      list-style-type: none;
      padding: 0;
      .make-item,
      .model-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        &:hover {
          background-color: #d8d8d8;
        }
      }
      .make-item {
        padding: 12px 16px;
      }
      .model-item {
        padding: 12px 16px 12px 40px;
      }
    }
  }
  .checkbox {
    position: relative;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
    margin-right: 16px;
    background-color: transparent;
    border: 1.5px solid #6e6e6e;
    border-radius: 0;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .highlighted {
    background-color: #f2e3ac;
    font-weight: 600;
  }
}
@media screen and (max-width: 1023px) {
  .search-input {
    .dropdown-field {
      .items-list {
        max-height: calc(100vh - 20px - 62px - 62px);
        padding: 0 0 90px 0;
      }
    }
    &__input {
      height: 62px;
      padding-left: 56px;
      border: 1.5px solid #000000;
      &:focus {
        border: 1.5px solid #0065b1;
      }
    }
    .text-field-container {
      position: relative;
      & > svg {
        position: absolute;
        top: 19px;
        left: 16px;
        cursor: pointer;
      }
    }
    .dropdown-field {
      left: -56px;
      width: 100vw;
      &__header {
        padding-top: 24px;
        padding-bottom: 13px;
        margin: 0 16px;
        border-bottom: 1px solid #d8d8d8;
        p {
          font-size: 20px;
          font-weight: 600;
          margin: 0;
        }
      }
    }
  }
}


.myCheckbox {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  position: relative;
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  margin-right: 16px;
  background-color: transparent;
  border: 1.5px solid rgb(110, 110, 110);
  border-radius: 0px;
}

.ACTIVE {
  background-color: #0065b1;
  border: 1.5px solid #0065b1;
  &:after {
    position: absolute;
    top: 0px;
    left: 4px;
    width: 6px;
    height: 11px;

    border-style: solid;
    border-color: #ffffff;
    border-image: initial;
    border-width: 0px 1.5px 1.5px 0px;
    transform: rotate(45deg);
    content: "";
  }
}
.SEMIACTIVE {
  background-color: #0065b1;
  border: 1.5px solid #0065b1;
  &:after {
    position: absolute;
    top: 7px;
    left: 2px;
    width: 10px;
    height: 0px;

    border-style: solid;
    border-color: #ffffff;
    border-image: initial;
    border-width: 0px 1.5px 1.5px 0px;
    content: "";
  }
}


.search-input{
  .text-field-container{

  }
}