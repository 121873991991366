.how-to-buy-tile-container {
  display: flex;
  flex-direction: column;

  .visual-elements {
    position: relative;

    .line {
      position: absolute;
      width: 60%;
      background-color: #d8d8d8;
      height: 2px;
      z-index: 1;
      bottom: 24px;
      left: 30%;
    }

    .icon-container {
      width: 48px;
      height: 48px;
      position: relative;
      z-index: 2;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .how-to-buy-tile-description {
    width: 190px;
  }
}
