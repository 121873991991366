@import "../../../assets/styles/variables";

@keyframes progress-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@keyframes loader-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@-webkit-keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lc {
  width: 320px;
  height: 620px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 16px;
  right: 16px;
  transition: all 0.1s ease-out;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.08);
  opacity: 1;
  z-index: $z-index-chat;
  border: 1px solid #D8D8D8;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  button[disabled] {
    cursor: initial;
  }

  .lc-btn {
    height: 44px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background-color: #ae0000;
    border-radius: 22px;
    padding: 0 16px;
    width: 100%;
  }

  button:active,
  textarea:active,
  input:active,
  button:focus,
  textarea:focus,
  input:focus {
    outline: none;
  }

  &.minimized {
    transition: transform 0.2s ease-out;
    bottom: 0;
    box-shadow: none;
    transform: translate(0, calc(100% - 56px));
    z-index: $z-index-chat-minimized;

    #lc-header {
      justify-content: start;

      .lc-options {
        display: none;
      }

      #minimize {
        transform: rotate(180deg);
      }
    }

    #chat {
      opacity: 0;
    }
  }

  &.closed {
    transform: scale(0.8) translate(10%, 30%);
    opacity: 0;
    z-index: -1;
  }

  .start-chat-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 16px;
    }
  }

  .lc-overlay-wrapper {
    .lc-overlay,
    .lc-overlay-content,
    .lc-overlay-close {
      position: absolute;
      z-index: 3;
    }

    .lc-overlay {
      background: rgba(#26221E, 0.75);
      width: 100%;
      height: 100%;
    }

    .lc-overlay-close {
      right: 2em;
      top: 2em;
      display: flex;
      margin-left: auto;
      margin-bottom: 16px;

      svg {
        path {
          stroke: #0065B1;
        }
      }
    }

    .lc-overlay-content {
      width: calc(100% - 2rem);
      top: 1em;
      left: 1em;
      color: rgb(17, 17, 17);
      background: rgb(255, 255, 255);
      padding: 56px 1em 1em;
    }
  }

  .lc-overlay-close-chat {
    &.hide {
      display: none;
    }

    .closing-chat-content,
    .transcript-content {

      &__icon {
        display: flex;
        justify-content: center;
      }

      &__content {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #26221E;
      }
    }

    .closing-chat-content {

      &__icon {
        margin-bottom: 16px;

        svg {
          width: 48px;
          height: 48px;
        }
      }

      &__content {
        margin-bottom: 32px;
      }
    }

    .transcript-content {
      &__icon {
        margin-bottom: 10px;
      }

      &__content {
        word-break: break-word;
        margin-bottom: 16px;
      }
    }
  }

  .lc-files {
    width: 100%;
    background-color: #FFFFFF;
    bottom: 64px;
    left: 16px;
    right: 16px;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    padding: 14px 14px 6px;

    .lc-files-remove-icon {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      right: 16px;
      top: 72px;
      transition: all 0.1s ease;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      &:disabled {
        cursor: default;
        background-color: rgba(0, 0, 0, 0.5);
      }

      svg {
        position: absolute;

        path {
          stroke: #FFFFFF;
        }
      }
    }

    & > * {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      width: calc(50% - 4px);
      margin: 2px;
      min-width: calc(50% - 4px);
      max-width: 100%;
      height: 150px;
      position: relative;
      user-select: none;

      .lc-file-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #6E6E6E;
        margin-top: 4px;
      }

      &.lc-file-error {
        height: auto;
        margin-bottom: 10px;

        .lc-error-message {
          color: #d21313;
          padding-right: 40px;
          text-overflow: unset;
          overflow: unset;
          white-space: unset;
          height: auto;
        }
      }

      &:only-child {
        width: 100%;
      }

      .lc-loader {
        display: none;
        right: 8px;
        top: 8px;
      }

      &.loading {
        .lc-loader {
          display: block;
        }

        .lc-image,
        .lc-file,
        .lc-files-remove-icon,
        .lc-file-name {
          transition: all 0.2s ease;
          opacity: 0.6;
        }
      }
    }

    .lc-file,
    .lc-image {
      flex: 1 1 0%;
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      position: relative;
      align-items: center;
      justify-content: center;
      min-height: 130px;
      max-height: 130px;
      background-color: #F6F6F6;
    }

    .lc-image-container {
      .lc-image {
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .lc-options-list {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.08);
    opacity: 1;

    .lc-option {
      height: 56px;
      background-color: #FFFFFF;
      padding: 16px;
      display: flex;
      gap: 16px;
      cursor: pointer;
      transition: transform 0.1s ease 0s;

      svg {
        path {
          stroke: #0065B1;
        }
      }

      &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #26221E;
        white-space: nowrap;
        user-select: none;
        padding: 0;
      }

      &.disabled {
        cursor: default;
        background-color: #F6F6F6;

        .lc-option__title {
          cursor: default;
        }
      }
    }

    &.hide {
      opacity: 0;
      transition: opacity 0.2s ease-in-out 0s;
      z-index: -1;

      .lc-option {
        transform: translateY(5px);
      }
    }
  }

  form {
    padding: 0 16px;

    & > * {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .message-container {
      margin-bottom: 4px;
    }

    fieldset {
      border: 0px;
      margin: 0px;
      padding: 0px;
      position: relative;
      max-height: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #26221E;

      legend {
        padding: 0px;
        display: inline-block;
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-word;
        margin-bottom: 10px;
      }

      label {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .stars-list {
        display: flex;
        gap: 16px;

        .rating-label {
          &.active {
            svg {
              path {
                stroke: transparent;
                fill: #EFD063;
              }
            }
          }
        }

        input {
          &:checked + div svg {
            path {
              stroke: transparent;
              fill: #EFD063;
            }
          }
        }
      }
    }

    input[type="text"],
    input[type="name"],
    input[type="email"],
    input[type="tel"],
    input[type="question"],
    input[type="subject"],
    textarea {
      display: flex;
      width: 100%;
      height: 62px;
      padding: 0 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #6E6E6E;
      border: 1.5px solid #D8D8D8;
      background: #FFFFFF;
      appearance: none;
      resize: none;
    }

    textarea {
      padding: 19px 16px;
      height: auto;
    }

    label {
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #6E6E6E;

      input {
        position: absolute;
        top: 9px;
        left: 9px;
        width: 1px;
        height: 1px;
        opacity: 0;
        z-index: -1;
      }

      span {
        position: relative;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 18px;
        height: 18px;
        margin-right: 16px;
        background-color: #ffffff;
        border: 1.5px solid #6e6e6e;
        border-radius: 0;
        margin-top: 4px;
        display: inline-block;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 18px;
          height: 18px;
          transform: translate(-50%, -50%);
          content: "";
        }

        &:after {
          position: absolute;
          content: "";
        }
      }

      &.checkbox-label {
        color: #26221E;

        input {
          &:checked + span {
            background-color: #0065b1;
            border: 1.5px solid #0065b1;

            &:after {
              display: block;
            }
          }
        }

        span {
          &:after {
            top: 0px;
            left: 4px;
            width: 6px;
            height: 11px;
            border-style: solid;
            border-color: rgb(255, 255, 255);
            border-image: initial;
            border-width: 0px 1.1px 1.1px 0px;
            transform: rotate(45deg);
            content: "";
          }
        }
      }

      &.radio-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        input {
          &:checked + span {
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(0, 101, 177);

            &:after {
              display: block;
              background-color: rgb(0, 101, 177);
            }
          }
        }

        span {
          border-radius: 50%;

          &:after {
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: transparent;
          }
        }
      }
    }

    .checkbox-header {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      color: #6E6E6E;
      margin-bottom: 16px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px 0;
    }

    .message-container {
      margin-left: 0;

      .message {
        padding-left: 0;
      }
    }

    &.lc-transcription-form {
      padding: 0;
    }
  }

  .lc-connection-status {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #ae0000;
    z-index: 2;
    font-size: 16px;
    line-height: 24px;
    transform: translateY(100%);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
  }

  .lc-agent-container {
    position: relative;
  }

  #lc-agent {
    background-color: #FFFFFF;
  }

  .agent-container {
    display: flex;
    align-items: center;
    padding: 12px 16px;

    img {
      margin-right: 16px;
      width: 48px;
      height: 48px;
    }

    .agent-name {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #26221E;
    }
  }

  #lc-header {
    flex-shrink: 0;
    padding: 16px;
    height: 56px;
    display: flex;
    justify-content: center;
    background-color: #0065B1;
    color: #FFFFFF;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;


    & > * {
      display: flex;
      align-items: center;
    }

    .lc-options,
    #minimize,
    #lc-header-close {
      position: absolute;
      top: 16px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      & > svg {
        cursor: pointer;

        path {
          stroke: #FFFFFF;
        }
      }
    }

    .lc-options {
      left: 8px;
    }

    .lc-options-list {
      left: 14px;
      top: 42px;
    }

    #minimize {
      transition: all 0.1s ease;
      right: 48px;
    }

    #lc-header-close {
      right: 8px;
    }
  }

  #chat {
    flex-grow: 1;
    background: #F6F6F6;
    overflow-y: auto;
    padding-top: 20px;
    transition: opacity 0.2s ease-out;
  }

  .chat-item {
    display: flex;
    max-width: 100%;
    padding: 2em 1em;

    border-bottom: 1px solid #dce1e6;
  }

  .chat-item:hover {
    cursor: pointer;
    background-color: #d9e1ea;
  }

  .chat-item-content {
    flex-grow: 1;
    min-width: 0;

    color: #636f7e;
  }

  .chat-item-content-row {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    line-height: 1.5em;
  }

  .chat-item-content-authors {
    flex-grow: 1;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .chat-item-content-text {
    font-size: 0.875em;
  }

  .chat-item-content > div:first-child {
    display: flex;

    opacity: 0.6;
    font-size: 0.75em;
  }

  .message-list {
    flex-grow: 1;
    display: none;
    flex-direction: column;
    align-items: flex-end;
    background-color: #e9eef4;
    overflow-y: auto;
    min-height: 0px;
    position: relative;
  }

  .message-container {
    max-width: 90%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0 16px 16px;

    .message {
      background-color: #0065B1;
      padding: 8px;
      color: #fff;
      max-width: 100%;
      position: relative;
      white-space: pre-line;

      a {
        text-decoration: underline;
      }

      .message-image-container {
        display: flex;
        background-color: #F6F6F6;
        max-width: 200px;
        min-height: 100px;
      }

      img {
        height: 100%;
        object-fit: cover;
        display: flex;
        max-width: 200px;
      }

      .top-container-message {
        display: flex;
        margin-bottom: 4px;

        .time-message,
        .author-message {
          font-size: 10px;
          line-height: 16px;
          font-weight: 600;
        }
      }

      .message-text {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        word-wrap: break-word;

        .message-text-title {
          margin-bottom: 8px;
        }

        .message-text-label {
          margin-bottom: 2px;
          color: #6E6E6E;
        }

        .message-text-value {
          margin-bottom: 8px;
        }
      }
    }

    &.message-has-not-sent {
      .message {
        opacity: 0.7;
      }
    }

    &.indicator {
      .message {
        .message-text {
          display: flex;

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #26221E;
            -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
            animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
            margin-right: 2px;
          }

          span:nth-child(1) {
            -webkit-animation-delay: 200ms;
          }

          span:nth-child(2) {
            -webkit-animation-delay: 300ms;
          }

          span:nth-child(3) {
            -webkit-animation-delay: 400ms;
          }
        }
      }
    }

    &.agent,
    &.customer {
      .message {
        &:before,
        &:after {
          content: "";
          position: absolute;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid #0065B1;
          top: 0;
        }
      }
    }

    &.agent {
      align-self: flex-start;

      .message {
        background-color: #fff;
        margin-left: 12px;

        &:before {
          transform: translateX(-50%);
          border-top: 12px solid #FFFFFF;
          left: 0;
        }

        &:after {
          content: none;
        }

        .top-container-message {
          color: #6E6E6E;
        }

        .message-text {
          color: #26221E;
        }
      }
    }

    &.customer {
      align-items: flex-end;

      a {
        color: #FFFFFF;
      }

      .message {
        margin-right: 12px;

        &:before {
          content: none;
        }

        &:after {
          transform: translateX(50%);
          right: 0;
        }
      }
    }

    &.system {
      align-self: center;

      .message {
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        padding: 8px 8px 8px 0;
        max-width: 100%;

        .top-container-message {
          color: #6E6E6E;
        }

        .message-text {
          color: #6E6E6E;
        }
      }
    }

    .message-status {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-align: right;
      color: #6E6E6E;
      margin-top: 4px;
    }
  }

  .notify {
    padding: 8px;
    color: #6E6E6E;
    word-wrap: break-word;
    max-width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .agent-avatar {
    width: 2.4em;
    height: auto;
    border-radius: 50%;
    display: block;
    margin-right: 0.5em;
  }

  .customer-letter-wrapper {
    width: 2.4em;
    height: auto;
    border-radius: 50%;
    display: block;
    margin-right: 0.5em;

    background-color: #e6958d;
  }

  .customer-letter {
    text-align: center;
    display: block;
    line-height: 2.4em;
    color: #fff;
  }


  .lc-footer {
    background: #F6F6F6;
    position: relative;
    flex-shrink: 0;

    &__icons {
      position: absolute;
      top: 0px;
      height: 100%;
      display: flex;
      align-items: center;
      right: 16px;
    }

    .file-input {
      max-width: 0;
    }

    .lc-options-list {
      right: 16px;
      bottom: -14px;
      transform: translateY(-100%);
    }
  }

  .textarea-wrapper {
    display: flex;
    width: 100%;
    position: relative;

    .message-input {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      padding: 20px 88px 20px 16px;
      flex-grow: 1;
      border: 0;
      resize: none;
      height: 56px;
      overflow-y: auto;
      max-width: 100%;
      min-width: 0px;
      appearance: none;
      color: #6E6E6E;
      background-color: #FFFFFF;

      &:disabled {
        &::placeholder {
          opacity: 0.6;
        }
      }
    }

    &::placeholder {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #6E6E6E;
    }
  }

  #send-button {
    margin-right: -6px;

    svg {
      path {
        stroke: #D8D8D8;
      }
    }

    &.active {
      svg {
        path {
          stroke: #6E6E6E;
        }
      }
    }

    &:disabled {
      svg {
        path {
          stroke: #D8D8D8;
        }
      }
    }
  }

  .file-button[disabled] {
    svg {
      path {
        stroke: #D8D8D8;
      }
    }
  }

  .lc-loader {
    position: absolute;
    text-indent: -9999em;
    border-top: 4px solid #d8d8d8AA;
    border-right: 4px solid #d8d8d8AA;
    border-bottom: 4px solid #d8d8d8AA;
    border-left: 4px solid #545454;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: circleAnimation 1.1s infinite linear;
    animation: circleAnimation 1.1s infinite linear;
    z-index: 1;

    &, &:after {
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }

    &.hide {
      z-index: -1;
      opacity: 0;
    }

    .circle {
      transform-origin: 36px 36px;
      animation: spin 1s infinite linear;
    }
  }
}

.lc-button {
  position: fixed;
  bottom: 188px;
  left: 16px;
  display: flex;
  z-index: $z-index-chat-button;
  opacity: 0;
  transition: all 0.2s ease-out;
  transform: scale(0.1);

  &:hover {
    cursor: pointer;

    svg rect.border-rect {
      stroke-opacity: 0.3;
    }
  }

  &.closed {
    opacity: 1;
    transform: none;
  }
  
  &.hide {
    display: none;
  } 
}

@media screen and (max-width: 1023px) {
  .lc-button {
    bottom: 24px;
    left: 24px;
  }
}

@media screen and (max-width: 425px) {
  .lc {
    width: 100%;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    height: 100%;
    z-index: $z-index-chat-mobile;
    max-height: 100vh;

    &.minimized {
      width: calc(100% - 56px);
      transform: translate(28px, calc(100vh - 56px));
    }
  }
}
