.toast-wrapper {
    width: 100%;
    height: 64px;
    z-index: 101;
    position: fixed;
    bottom: 16px;
    display: flex;
    justify-content: center;

    .main-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 645px;
        height: 100%;
        color: #ffffff;
        padding: 0 20px;

    }
    
}