$z-index-cookie-bar: 10000;
$z-index-navbar-hr: 9906;
$z-index-menu: 9905;
$z-index-shadow: 9900;

$z-index-chat-mobile: 90;

$z-index-filters-mobile: 20;
$z-index-search-mobile: 20;
$z-index-calculator-mobile: 20;
$z-index-menu-mobile: 20;

$z-index-navbar-mobile: 16;

$z-index-calculator: 15;
$z-index-navbar: 15;

$z-index-fast-buttons: 13;
$z-index-call-button: 13;

$z-index-chat-button: 7;
$z-index-chat-minimized: 7;
$z-index-chat: 7;

$z-index-cta-mobile: 6;
$z-index-gallery-container: 6;

$paddingMobile: 16px;
$paddingDesktop: 32px;

$containerWidth: 1308px;
$contentContainerWidth: 864px;