.search-container {
  overflow: hidden;
  & > hr[data-component="Stripe"] {
    position: absolute;
  }

  display: flex;
  height: 150vh;
  width: 100%;
  z-index: 20;
  background: #f6f6f6;
  position: fixed;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: flex-start;

  .mobile-list-baner-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding-left: 16px;
    position: relative;
    width: auto;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .search-panel-container {
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .atc-container {
      display: flex;
      width: 100%;
      padding: 0 16px;
      .atc-input {
        flex: 1;
      }
      .back-arrow-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
      }
    }

    a {
      bottom: 10px;
      display: flex;
      justify-content: center;
    }
    .content-area {
      width: 100%;
      padding: 0 16px;
      overflow: scroll;
      height: calc(100vh - 72px);
      padding-bottom: 150px;

      &__history,
      &__recomended {
        .content-area__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px 0 14px;
          border-bottom: 1px solid #d8d8d8;

          .content-area__clear-button {
            margin: 0;
            cursor: pointer;
            color: #0065b1;
            font-weight: 600;
          }
        }
        .content-area__element {
          display: flex;
          align-items: center;
          p {
            flex: 1;
            padding: 13px 0;
            border-bottom: 1px solid #d8d8d8;
          }
          a {
            width: 100%;
          }
        }
        .content-area__element:nth-last-child(1) {
          p {
            border-bottom: none;
          }
        }
      }
    }
  }
}
.search-btn-container {
  z-index: 1101;
  bottom: 0px;
  width: 100%;
  background: linear-gradient(0deg, #ffffff 0%, #ffffff00 100%);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 24px;
  pointer-events: none;

  .search-btn-link {
    width: fit-content;
    pointer-events: auto;
    button {
      width: 240px;
    }
  }
}

// MD component
.atc-list {
  left: 0px !important;
  width: 100%;
  background-color: #f6f6f6 !important;
  & > div {
    width: 100%;
    padding: 0 !important;
    & > div[data-component="Box"] {
      display: none;
    }
    & > div[data-component="List"] {
      min-height: calc(100vh - 72px);
      max-height: unset;
      ul {
        padding-bottom: 160px;
      }
    }
  }
}
