.contact-icons-container {
    .page-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;   
        height: 100vh;
        z-index: 3;
        display: none;
        background-color: #000000;
        opacity: 0.5;
        overflow: hidden;
        
    }

    .icons-container{
        position: fixed;
        top: 564px;
        left: 24px;
        display: flex;
        flex-direction: column;
        z-index: 4;

        &:hover {
            cursor: pointer;
        }

        &:hover + .page-background {
            display: flex;
        }

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            &.reminder {
                background-color: #0065b1;
                margin-bottom: 16px;

                .icon {
                    background: url(./img/bell.svg) center center no-repeat;
                }
            }

            &.call {
                background-color: #ae0000;
                margin-bottom:16px;

                .icon {
                    background: url(./img/phone-handle.svg) center center no-repeat;
                }
            }

            &.text-us {
                background-color: #ae0000;

                .icon {
                    background: url(./img/speech-bubble.svg) center center no-repeat;
                }
            }

            
            .icon {
                width:48px;
                height: 48px;
            }

            .icon-description {
                display: none;
            }
        }
    }
}