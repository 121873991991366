@import "./assets/styles/colors.scss";
@import "./assets/styles/variables.scss";

body, html {
  height: 100%
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  margin: 0;
  #callpage {
    .cp-button-section {
      display: none !important;
    }
  }
}

a {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  background-color: #f6f6f6;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .main-site-container {
    flex: 1;
  }
}

a[data-component="Tile"],
div[data-component="Tile"] {
  padding: 20px;
}

.text-error {
  color: #e90a0a;
}

.mleasing-form-error-text {
  margin-top: 5px;
}

.mleasing-main-container {
  margin: auto;
  max-width: 1600px;
  width: 100%;

  &.two-thirds-width {
    width: 66%;
    max-width: 866px;
    margin: 0 0 0 auto;
  }

  &.one-third-width {
    width: calc(34% - 24px);
    max-width: 419px;
    margin: 0 auto 0 24px;
  }

  &.without-max-width {
    max-width: unset;
  }

  &.without-padding {
    padding: 0;
  }

  &.bg-white {
    background-color: white;
  }

  &.bg-gray {
    background-color: #f6f6f6;
  }

  &.bg-transparent {
    background-color: transparent;
  }

  &.bg-black {
    background-color: #000000;
  }

  .content-wrapper {
    width: 100%;
    max-width: $containerWidth;
    margin: auto;

    &.small,
    & > .left-wing {
      max-width: 866px;
    }

    & > .right {
      width: 419px;
    }

    &.small {
      margin: auto;
    }

    & > .flex-end {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.show-on-desktop {
  display: flex;

  &.direction-column {
    flex-direction: column;
  }
}

.show-on-mobile {
  display: none;
}

.show-more-element {
  color: #0065b1;
  cursor: pointer;
}

.first-letter-capitalize {
  &:first-letter {
    text-transform: capitalize;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.nav_bar-mobile-modal-container {
  display: none;
}

.without-decoration {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-decoration: none;
}

button.icon-button {
  border-radius: 50%;
  cursor: pointer;
  border: 1.5px solid #FFFFFF;

  &.button-transparent {
    svg {
      path {
        stroke: #FFFFFF;
      }
    }

    &:not(:disabled) {
      > span {
        &:hover,
        &:active {
          background-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 1390px) {
  .mleasing-main-container {
    .content-wrapper {
      & > .left-wing {
        padding-left: $paddingDesktop;
        padding-right: $paddingDesktop;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .main-site-container {
    max-width: unset;
  }

  .nav_bar-mobile-modal-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    background: #ffffffff;
    width: 100%;
    height: 100%;
    overflow: scroll;
    bottom: 0px;
    padding-top: 66px;

    &.mobile-hamburger-menu {
      p {
        padding: 5px 0px;
        width: 100%;
      }

      button {
        width: 60%;
        margin: 0 20%;
        margin-top: 30px;
      }
    }
  }
  .without-decoration {
    width: 100%;
  }

  .mleasing-main-container {
    &.two-thirds-width {
      width: 100%;
      max-width: unset;
    }

    &.one-third-width {
      width: 100%;
      max-width: unset;

      &.visible-in-struct {
        left: unset;
        margin-left: 0;
        top: unset;
        position: relative;

        .content-wrapper {
          & > .flex-end {
            align-items: flex-start;

            & > .calculator-container {
              position: relative;
              overflow: unset;
              top: 0;
              border: 0;
              width: 100%;
              max-width: unset;
              min-width: unset;
            }
          }
        }
      }
    }

    .content-wrapper {
      .left-wing {
        max-width: unset;
        padding-left: $paddingMobile;
        padding-right: $paddingMobile;

        &.mobile-padless {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .show-on-desktop {
    display: none;
  }

  .show-on-mobile {
    display: flex;
  }

  div[data-component="Grow"] {
    & > div[data-component="Box"] {
      padding: 16px;

      & > div[data-component="Box"] {
        outline: none;
      }
    }
  }
}

.viewed-cars-button-container {
  position: relative;

  &.centered {
    display: flex;
    align-items: center;

    & > p {
      margin-left: 4px;
    }

    .viewed-cars-count-circle {
      top: -4px;
      right: 10px;
      left: unset;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .viewed-cars-count-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ae0000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    color: #fff;
    position: absolute;
    z-index: 2;
    left: 29px;

    & > span {
      color: white;
    }
  }
}

.background-shadow{
  z-index: $z-index-shadow;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}