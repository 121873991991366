.lb-container {
  position: relative;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 20px 0px 40px 0px;
}

.lb-shadow {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 0;
}

.mobile-list-baner-navbar {
  display: none;
}

.lb-main-container {
  padding: 0 136px;
  position: relative;
  z-index: 6;

  .contractor-type-container {
    display: flex;
    margin-left: 10px;
  }

  .selects-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 20px;
    position: relative;

    .textfield-container-small {
      width: 201px;
      margin: 10px;
      margin-top: 5px;
    }
  }
  .search-button-container {
    display: flex;
    justify-content: flex-end;
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      right: 15px;
      bottom: 22px;
      .clean-filters-btn {
        cursor: pointer;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        margin: 0 20px 0 5px;
      }
    }
  }
}

.filters__accordion-header {
  margin: 0 16px;
  border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 1023px) {
  .lb-container {
    display: none;
    background-color: #f6f6f6 !important;
    padding: 0;
  }
  .lb-shadow {
    display: none;
  }

  .mobile-list-baner-navbar {
    display: flex;
    width: 100%;
    height: 60px;
    padding: 20px 16px;
    justify-content: space-between;
    align-items: center;
    .icons-container {
      display: flex;
    }
  }
  .lb-main-container {
    margin-bottom: 300px;
    background-color: #f6f6f6;

    .contractor-type-container {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 16px;
      margin: 0 16px;
      label,
      span {
        color: #000000;
      }
    }
    padding: 0;
    .selects-container {
      padding-top: 10px;
      background-color: #f6f6f6;
    }
    .search-button-container {
      justify-content: center;
      background-color: #f6f6f6;

      .button-container {
        position: fixed;
        background: linear-gradient(rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 1));
        flex-direction: column;
        gap: 16px;
        padding: 54px;
        width: 100%;
        right: 0;
        bottom: 0;
        p {
          display: none;
        }
        button {
          width: 80%;
        }
      }
    }
  }
}
