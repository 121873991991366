@import "../../../assets/styles/variables";

.why-us {
    padding: 0 $paddingDesktop;

    &__container {
        padding: 80px 0;

        .why-us__typo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            padding-bottom: 32px;
        }

        .why-us__items {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 24px;

            .why-us__item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 24px;
                padding: 24px 16px;
                max-width: 343px;
                border-radius: 24px;
                background: #F6F6F6;

                .why-us__content {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }

        @media screen and (max-width: 1023px) {
            padding: 24px 0;

            .why-us__typo {
                align-items: flex-start;
                padding: 0 0 32px;
            }

            .why-us__items {
                flex-direction: column;
                gap: $paddingMobile;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .why-us {
        padding: 0 $paddingMobile;
    }
}