.equipment-list {
  padding: 40px 0;
  .equipment-list__category-name-container {
    display: flex;
    margin-bottom: 24px;
    cursor: pointer;
    .equipment-list__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      transition: transform 0.2s;

      &.upside-down {
        transform: rotate(180deg);
      }
    }
  }
  .equipment-list__category-name-container:last-child {
    margin-bottom: 0;
  }

  .equipment-list__category-content {
    margin-bottom: 24px;
    .equipment-list__category-lists {
      display: flex;
      .equipment-list__category-list {
        margin: 0;
        width: 50%;
        .equipment-list__category-item {
          list-style-type: square;
          color: #0065b1;
          font-size: 24px;
          margin-bottom: 16px;
        }
      }
      .equipment-list__category-list:first-child {
        margin-right: 69px;
      }
    }
  }
  .equipment-list__category-content:last-child {
    margin-bottom: 0;
  }
  .equipment-list__extend-button {
    cursor: pointer;
    color: #0065b1;
  }
}

@media screen and (max-width: 1023px) {
  .equipment-list {
    padding: 24px 0;
    .equipment-list__category-content {
      .equipment-list__category-lists {
        flex-direction: column;
        .equipment-list__category-list {
          margin: 0 8px;
          width: 100%;
        }
      }
    }
  }
  .equipment-list:last-child {
    padding: 24px 0 0;
  }
}
