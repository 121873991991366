.additional-package-info-tile-container {
  display: flex;
  width: 100%;
  padding-top: 12px;
  margin-top: 24px;

  .gold-star {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(./../../../assets/icons/star-gold.svg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 8px;
    margin-bottom: 2px;
  }

  &.small {
    max-width: 421px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .image-container {
    width: 44px;
    margin-right: 16px;
  }

  .text-container {
    padding-right: 52px;

    .title-container {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }
  }
  &.financing-page {
    flex-direction: column;
    margin: auto;
    .image-container {
      width: 64px;
      height: 64px;
      background-color: #f6f6f6;
      border-radius: 16px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-container {
      p {
        color: #26221e;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .additional-package-info-tile-container {
    width: 100%;

    .image-container {
      width: 24px;
    }

    .text-container {
      padding-right: 0;

      .title-container {
        margin-top: 0;
      }
    }
  }
}
