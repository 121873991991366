@import "../../../assets/styles/variables.scss";

.contact-modal{
  &__shadow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9500;
  }
  &__tile{
    position: relative;
    padding: 24px;
    border-radius: 16px;
    background: #ffffff;
    z-index:9501 ;
    overflow: scroll;
    max-height: 100vh
  }
  &__close{
    position: absolute;
    top: 16px;
    right: 16px;
    z-index:9502;
    cursor: pointer;
  }
}

@media screen and (max-width: 1023px) {
  .contact-modal {
    &__shadow {
      overflow: scroll;
      align-items: flex-start;
      background-color: #fff;
    }
    .car-tile-summary-container {
      padding: 0;
    }
  }
}

@media screen and (max-width:768px) {
  .contact-modal{
    &__shadow{
      align-items: flex-start;
      overflow-y: scroll;
    }
    &__tile{
      padding: $paddingMobile 0;
      border-radius: 0;
    }
  }
}