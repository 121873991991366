@import "../../../assets/styles/variables";

.award-section{
    background: #F6F6F6;
    &.whitebg{
        background: white;
    }
    &__container{
        width: 100%;
        max-width: 1308px;
        margin: auto;
        padding: 80px $paddingDesktop;
        display: flex;
        gap:24px
    }
    &__typo{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .highlighted{
            color: red;
        }
    }
}

@media screen and (max-width: 1023px) {
    .award-section{
        &__container{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}