.main-pros-section-container {
  background-color: #ffffff;

  .mleasing-main-container {
    display: flex;
    justify-content: center;

    .content-wrapper {
      display: flex;
    }

    .information-tile-container {
      width: 100%;
      max-width: 300px;
      padding-left: 0;

      & > div {
        background-color: #ffffff;
      }
    }
  }
}

.details-page-container .main-pros-section-container {

  .mleasing-main-container {
    padding: 0;
    
    .information-tile-container {
      background-color: initial;
      max-width: unset;
      width: auto;
    }
  }
}

@media screen and (max-width: 1023px) {
  .main-pros-section-container .mleasing-main-container {
    .information-tile-container {
      max-width: unset;

      & > div[data-component="Tile"] {
        padding-bottom: 10px;
        padding-top: 30px;
      }
    }

    .swiper {
      & > * {
        & > button {
          margin-bottom: 16px;
        }
      }

      &.swiper-container-horizontal[data-ds-navigation='true'] {
        .swiper-pagination {
          bottom: 26px;
        }
      }
    }
  }
}
