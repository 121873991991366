.landingPageAfi {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
  &__top {
    display: flex;
    flex: 1;
    .landingPageAfi__left {
      flex: 1;
    }
    .landingPageAfi__background-image {
      width: 100%;
    }

    .landingPageAfi__contact-form-section {
      width: 372px;
      .contact-form-section-container {
        padding: 24px;
        box-shadow: none;
      }
    }

    .landingPageAfi__bottom-text{
      margin: 20px 28px 28px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .landingPageAfi {
    .landingPageAfi__top {
      flex-direction: column;

      .landingPageAfi__contact-form-section {
        width: 320px;
      }
    }
  }
}
