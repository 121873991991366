.categories-links {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .categories-links__column {
    flex: 1;

    .categories-links__column-header {
      margin-bottom: 24px;
    }

    .categories-links__column-elements {
      a,
      a:visited,
      a:hover {
        text-decoration: none;
      }

      .categories-links__column-element {
        cursor: pointer;
        p {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .categories-links {
    flex-direction: column;
    .categories-links__column {
      .hide-column-elements {
        display: none;
      }
      .categories-links__column-header {
        margin: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .categories-links__column-elements {
        margin-top: 10px;
        .categories-links__column-element {
          p {
            margin: 12px 0;
          }
        }
      
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }
}
