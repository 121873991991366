@import "../../../assets/styles/variables";

.breadcrumbs {
    padding: 16px $paddingDesktop;

    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

@media screen and (max-width: 1023px) {
    .breadcrumbs {
        padding: $paddingMobile;

        &__container {
            overflow-y: scroll;
        }
    }
}