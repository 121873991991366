@import "../../../assets/functions/get-color";

.contact-form-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;

  &.dark {
    & > .background,
    & {
      background-color: dark-mode-property("primary-background");
    }
  }

  .content-wrapper {
    max-width: 1360px;
    margin: auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 36px;
  }

  .contact-form-banner-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 600px;
  }

  .contact-form-banner-shadow-container {
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    background-position: 0 -50px;
    z-index: -1;
    background-color: rgba(18, 18, 18, 0.55);
  }

  .contact-form-title-container {
    width: 50%;
    max-width: 867px;
    z-index: 1;

    .previous-page-link {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }

    .title {
      width: 100%;
      max-width: 580px;
      line-height: 1.2;
      margin-bottom: 24px;
    }
  }

  .contact-form {
    justify-content: center;
    height: fit-content;
    width: 100%;
    max-width: 559px;
    margin-right: 24px;
    background-color: white;
    z-index: 3;
      &__header{
        display: flex;
        justify-content: center;
        width: 100%;
      }
  }

  .summary-tile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 23px 0;
    max-width: 546px;
    min-width: 320px;
  }


  &--default {
    .content-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .contact-form-section-container {
      box-shadow: none;
      padding: 0;

      form{
        justify-content: center;    
      }
    }
  }
}



@media screen and (max-width: 1023px) {
  .contact-form-container {
    margin: 0 auto;
    padding: 0 0 48px 0;

    .content-wrapper {
      padding: 0;
    }

    .contact-form-title-container {
      width: 100%;
      padding-top: 0;

      .previous-page-link {
        display: none;
      }

      & > h1 {
        display: none;
      }
    }

    .contact-form {
      display: flex;
      max-width: unset;
      margin-right: unset;

      .contact-form-section-container {
        padding: 8px 16px 48px;
        box-shadow: unset;

        form {
          padding-right: 0;

          & > div[direction="row"] {
            display: flex;
            flex-direction: column;

            & > div[direction="row"] {
              width: 100%;
              margin-right: 0;

              &:first-of-type {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }

    .summary-tile {
      max-width: unset;
      width: 100%;
      margin-left: 0;
    }

    .contact-form-main-container {
      flex-direction: column;
    }
  }
}
