.navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .prev-next-buttons {
        display: flex;
        gap: 8px;
    }

    .previous-page-link-container {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}