.additional-package-tile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 8px;

  .tooltip-container {
    margin-left: 0;
  }

  .display-row {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .right-wing {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.indent {
    padding-left: 40px;
  }

  .left-wing {
    padding-right: 8px;
  }
}