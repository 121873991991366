@import "./src/assets/styles/colors";

.reservation-info-container {
  padding: 16px 24px;
}

.calculator-container {
  .price-container {
    .marketing-text {
      display: flex;
      gap: 10px;
      padding: 24px 0 16px 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .calculator-container {

    .rent-container,
    .leasing-container {
      margin-bottom: 16px;
    }
  }
}