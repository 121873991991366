@import '../../../assets/functions/get-color';
@import '../../../assets/styles/variables';

.cookies-container {
  height: 117px;
  width: 100%;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.08);
  padding: 24px 34px 24px 24px;
  margin: auto;
  justify-content: center;
  background: light-mode-property('primary-background');
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: $z-index-cookie-bar;

  &.dark {
    background-color: dark-mode-property('primary-background');
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
  }

  .description {
    width: 1200px;
  }
}

.cookies-container--new-background{
  background: #f9f9f9;
}

@media screen and (max-width: 1023px) {
  .cookies-container {
    flex-direction: column;
    height: auto;

    .description {
      width: 100%;
    }

    .button-container {
      padding-top: 10px;
      width: 140px;
      margin: 0 0 0 auto;
    }
  }
}
