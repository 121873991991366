.logged-desktop-button {
  display: flex;
  align-items: center;
  width: 140px;
  p {
    margin-right: 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.desktop-logged-nav {
  width: 309px;
  height: 299px;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: 15;
  background: #f6f6f6;
  .link-with-icon {
    padding: 10px;
    svg {
      margin-right: 10px;
    }
  }
}
.logged-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0;
  border-bottom: 1px solid #d8d8d8;
  &:nth-of-type(2) {
    border-bottom: none;
  }
  h2 {
    display: none;
  }
}

.centered {
  display: flex;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.nav-extended{
  border-bottom: 2px solid #0065B1;
}

.rotable {
  transition: transform 0.2s;

  &.upside-down {
    transform: rotate(180deg);
  }
}

.links {
  display: flex;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &__icon{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
}

@media (max-width: 1500px) {
  .desktop-logged-nav {
    right: 0;
  }
}

@media (max-width: 1023px) {
  .logged-container {
    &:nth-of-type(2) {
      border-bottom: 1px solid #d8d8d8;
    }
    h2 {
      display: flex;
      margin-bottom: 20px;
    }
  }
}
