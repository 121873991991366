@import "../../assets/functions/get-color";
@import "./variables";

.floating-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reservation-info-container {
  background-color: light-mode-property("primary-background");
  padding: 16px 24px 24px;
  margin-bottom: 32px;

  &.dark {
    background-color: dark-mode-property("primary-background");
  }

  .promoted-offer-sticker {
    padding: 0;
  }

  .tiles-wrapper {
    display: flex;

    & > div {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
}

.launch-preview-box {
  background-color: light-mode-property("primary-background");
  padding: 16px 24px 24px;
  margin-bottom: 32px;

  &.dark {
    background-color: dark-mode-property("primary-background");
  }
}

.calculator-container,
.new-car-calculator-container,
.auction-calculator-container {
  background-color: white;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  border-width: 1px;
  border-style: none solid solid solid;
  border-color: #ebebeb;
  width: 100%;
  min-width: 355px;
  max-width: 420px;
  z-index: $z-index-calculator;
  padding-bottom: 16px;

  &.none {
    display: none;
  }

  &.block {
    display: block;
  }

  .visible-on-desktop {
    display: flex;
  }

  .visible-on-mobile {
    display: none;
  }

  &.dark {
    background-color: dark-mode-property("primary-background");
    box-shadow: 4px 4px 0 0 rgba(255, 255, 255, 0.08);
    border-color: dark-mode-property("primary-background");

    .price-container {
      background-color: dark-mode-property("tertiary-background");
    }
  }

  .calculator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 8px 24px;
    position: relative;
    gap: 0 16px;
  }

  .tabs-container {
    display: flex;
    align-items: baseline;
    padding-left: 24px;
    gap: 16px;

    &.two-el-only {
      & > div {
      }
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;

      cursor: pointer;
      border-style: none none solid none;
      border-width: 2px;
      border-color: light-mode-property("color-wild-sand-gray");

      &.active {
        color: light-mode-property("color-endeavour-blue-ui");
        border-bottom-color: light-mode-property("color-endeavour-blue-ui");
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 0;
    background-color: inherit;

    .cash-container {
      padding: 20px 0;
    }

    .horizontal-radiobox-container {
      margin-bottom: 16px;
    }
  }

  .content-container-cash {
    padding: 4px 0 0;
    border-radius: 32px;
    .cash-container,
    .credit-container {
      padding: 20px 24px;
    }
    .price-container {
      &--cash {
        padding: 16px 24px;
        width: 100%;
        background-color: #ffffff;

        button {
          width: 100%;
        }
      }
    }
    .credit-container {
      background-color: #f6f6f6;
      margin-top: 14px;
      margin-bottom: 10px;
      button {
        width: 100%;
      }
    }
  }

  .collapsing-container {
    .content {
      height: auto;
      transition: 0.15s ease-in-out;

      & > div:not(:last-of-type) {
        padding-bottom: 16px;
      }

      & > div:last-of-type {
      }
    }

    .text-container {
      display: none;
      cursor: pointer;
      padding-bottom: 12px;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  .price-overview{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .price-container {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 32px 32px;
    background-color: light-mode-property("color-wild-solid-white");
    &--bottom {
      padding: 6px 20px 0 20px;
      border-top: 1px solid #b8b8b8;
      @media (min-width: 1281px) {
        margin-top: 12px;

      }
    }

    &.bg-white {
      background-color: light-mode-property("color-solid-white");
      padding: 0;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;

      &__summary{
        margin-bottom: 0;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      
      &__netto-text {
        max-width: 230px;
        margin-right: 16px;
        a {
          color: #26221e;
          text-decoration: underline;
        }
      }

      .amount-container {
        align-items: baseline;
      }
    }

    .calc-buttons-container {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      
      &__call {
        display: none;
      }
    }
  }
}

// .new-car-calculator-container {
//   box-shadow: 4px 4px 0 0 rgb(239, 208, 99);
//   border-color: rgb(239, 208, 99);
//   border-width: 2px;
//   border-top: 2px solid rgb(239, 208, 99);
// }

.cta-mobile {
  display: none;
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  border-radius: 32px 32px 0 0;
  padding: 16px;
  background-color: #f6f6f6;
  gap: 16px;
  border: 1px solid #d8d8d8;
  transition: all 0.3s;
  z-index: $z-index-cta-mobile;

  &__price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      text-align: right;
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    gap: 23px;

    &>* {
      width: 100%;

      &>* {
        width: 100%;
      }
    }
  }
  &.hide-cta {
    transform: translateY(105%);
  }
  
  & > * {
    width: 100%;
    
    & > * {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .calculator-container,
  .new-car-calculator-container,
  .auction-calculator-container {
    min-width: 300px;

    .calculator-header {
      padding: 16px 24px 8px;
    }

    .content-container {
      .collapsing-container {
        .text-container {
          display: flex;
        }

        &.active {
          .content {
            height: auto;
            overflow: unset;
          }
        }

        .content {
          height: 0;
          overflow: hidden;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {

  .floating-wrapper {
    position: fixed;
    top: 0;
    background-color: light-mode-property("primary-background");
    overflow-x: scroll;
    height: 100%;
    min-height: 100vh;
    display: none;
    z-index: $z-index-calculator-mobile;

    &.dark {
      background-color: dark-mode-property("primary-background");
    }
  }

  .reservation-info-container {
    display: block;
    padding: 16px;
    margin-bottom: 0;
  }

  .launch-preview-box {
    padding: 16px;
    margin-bottom: 0;
  }

  .calculator-container {
    padding-bottom: 170px;
    padding-top: 60px;
    
    .horizontal-radiobox-container {
      width: 100%;
    }
  }

  .calculator-container,
  .new-car-calculator-container,
  .auction-calculator-container {
    display: none;
    width: 100%;
    top: 0;
    left: 0;
    height: auto;
    position: relative;
    overflow: unset;
    min-height: 100vh;
    width: 100vw;
    max-width: unset;

    .price-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 16px;
      background-color: light-mode-property("color-solid-white");
      box-shadow: 0px -4px 0px rgba(0, 0, 0, 0.08);
      transition: all 0.3s;
      z-index: 1;
      
      &.scroll-bottom {
        box-shadow: none;
      }
      
      .calc-buttons-container {
        flex-wrap: wrap;
        
        &__call {
          display: block;
        }

        & > * {
          & > * {
            width: 100%;
          }
        }
      }
    }

    .content-container-cash {
      .price-container {
        &--cash {
          padding: 16px;
        }
      }
    }
  

    .visible-on-desktop {
      display: none;
    }

    .visible-on-mobile {
      display: flex;
      height: 100%;
      flex-direction: column;
      position: fixed;
      background: #ffffffff;
      width: 100%;
      overflow: scroll;
      bottom: 0;
    }
  }

  .calculator-container {
    background-color: light-mode-property("color-solid-white");
  }

  .cta-mobile {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .calculator-container,
  .new-car-calculator-container,
  .auction-calculator-container {
    .content-container-cash {
      .price-container {
        .calc-buttons-container {
          & > * {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 325px) {
  .calculator-container .calculator-header, .new-car-calculator-container .calculator-header, .auction-calculator-container .calculator-header{
  gap: 0;
  }
}

@media (max-height: 1000px) {
  .floating-wrapper {
    max-height: 86vh;
    overflow-y: auto;
  }
}