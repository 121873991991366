.how-to-buy-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .section-title {
    padding-top: 48px;
    margin-bottom: 16px;
  }

  .section-description {
    width: 100%;
    max-width: 720px;
    padding-bottom: 40px;
    text-align: center;
  }

  .steps-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 48px;

    & > div {
      .visual-elements {
        margin-bottom: 8px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 20%;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    padding: 0 6px;
  }
}

@media screen and (max-width: 1023px) {
  .how-to-buy-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .buttons-container {
      justify-content: center;

      div {
        width: 100%;
        display: flex;
        align-items: center;
      }
      button {
        width: auto;
      }
    }

    .section-title,
    .section-description {
      text-align: left;
      padding: 40px 16px 0;
    }

    .section-description {
      padding: 0 16px;
      margin-bottom: 40px;
    }

    .steps-container {
      flex-direction: column;
      margin-bottom: 40px;
      padding-left: 16px;
      padding-right: 16px;

      & > div {
        &,
        &:nth-child(n) {
          width: 100%;
        }
      }

      .how-to-buy-tile-container {
        flex-direction: row;
        width: 100%;

        .visual-elements {
          width: 60px;

          .circle-container {
            height: 48px;
          }

          .line {
            width: 2px;
            height: 60px;
            bottom: 0;
            left: 0;
            margin: auto;
            position: relative;
          }
        }

        & > .how-to-buy-tile-description {
          width: calc(100% - 60px);
          padding-bottom: 50px;
        }
      }
    }
  }
}
