.lp-taniej-z-mauto{

    a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,main,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
        border: 0;
        font-size: 100%;
        font: inherit;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        font-family: 'mBank';
    }
    p{
        font-weight: 400;
        font-family: 'mBank';
    }

    article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section {
        display: block
    }

    [hidden] {
        display: none
    }

    body {
        line-height: 1
    }

    menu,ol,ul {
        list-style: none
    }

    blockquote,q {
        quotes: none
    }

    blockquote:after,blockquote:before,q:after,q:before {
        content: "";
        content: none
    }

    table {
        border-collapse: collapse;
        border-spacing: 0
    }

    html {
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

    *,:after,:before {
        -webkit-box-sizing: inherit;
        box-sizing: inherit
    }

    body,html {
        overflow-x: hidden
    }

    @font-face {
        font-display: swap;
        font-family: "mbank";
        font-style: normal;
        font-weight: 300;
        src: url(./fonts/mbank-light.woff) format("woff"),url(./fonts/mbank-light.woff2) format("woff2")
    }

    @font-face {
        font-display: swap;
        font-family: "mbank";
        font-style: normal;
        font-weight: 400;
        src: url(./fonts/mbank-regular.woff) format("woff"),url(./fonts/mbank-regular.woff2) format("woff2")
    }

    @font-face {
        font-display: swap;
        font-family: "mbank";
        font-style: normal;
        font-weight: 600;
        src: url(./fonts/mbank-semibold.woff) format("woff"),url(./fonts/mbank-semibold.woff2) format("woff2")
    }

    @font-face {
        font-display: swap;
        font-family: "mbank";
        font-style: normal;
        font-weight: 700;
        src: url(./fonts/mbank-bold.woff) format("woff"),url(./fonts/mbank-bold.woff2) format("woff2")
    }

    body {
        background-color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        image-rendering: -webkit-optimize-contrast;
        text-rendering: optimizeLegibility
    }

    body,h1,h2,h3,h4,h5,h6 {
        color: #000;
        font-family: "mbank","Helvetica","Arial",sans-serif
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight: 700;
        line-height: 1.15
    }

    a {
        color: #000;
        text-decoration: none;
        word-break: break-word;
    }
    .section.car {
        a {
        font-family: "mbank", "Helvetica", "Arial", sans-serif;
        }
    }
    .benefits__item{
        a{
            color: #000;
            font-weight: normal;
            &:hover {
                text-decoration: underline
            }
        }
    }

    .footer__link--lp{

            font-family: "mbank", "Helvetica", "Arial", sans-serif;
            // font-family: "mbank", "Helvetica", "Arial", sans-serif !important;

    }

    a:hover {
        text-decoration: underline
    }

    img {
        display: block;
        height: auto;
        max-width: 100%
    }

    .btn {
        background-color: #ae0000;
        border-radius: 30px;
        color: #fff;
        display: inline-block;
        font-family: "mbank","Helvetica","Arial",sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -.04em;
        line-height: 1;
        padding: 16px 40px;
        -webkit-transition: background-color .25s ease;
        transition: background-color .25s ease
    }

    .btn:hover {
        background-color: #850000;
        text-decoration: none
    }
    .btn:active {
        color:#fff;
    }
    .btn:focus {
        color:#fff;
    }

    .btn--lg {
        font-size: 18px
    }

    @media screen and (max-width: 768px) {
        .btn--lg {
            font-size:16px
        }
    }

    @media screen and (max-width: 576px) {
        .btn {
            font-size:14px
        }
    }

    .btn-arrow {
        background-color: #fff;
        border: 2px solid #e90a0a;
        border-radius: 50%;
        color: transparent;
        display: block;
        height: 54px;
        position: relative;
        width: 54px
    }

    @media screen and (max-width: 768px) {
        .btn-arrow {
            height:50px;
            width: 50px
        }
    }

    @media (hover: hover) {
        .btn-arrow:hover:before {
            -webkit-transform:translateY(5px);
            transform: translateY(5px)
        }
    }

    .btn-arrow:before {
        background: url(./images/arrow.svg) 50% 50%/contain no-repeat;
        content: "";
        display: block;
        height: 16px;
        left: 50%;
        margin: -8px 0 0 -8px;
        position: absolute;
        top: 50%;
        -webkit-transition: -webkit-transform .25s ease;
        transition: -webkit-transform .25s ease;
        transition: transform .25s ease;
        transition: transform .25s ease,-webkit-transform .25s ease;
        width: 16px
    }

    .border {
        background: -webkit-gradient(linear,left top,right top,color-stop(0,#e90a0a),color-stop(27%,#e90a0a),color-stop(27%,#26221e),color-stop(32%,#26221e),color-stop(32%,#ff8600),color-stop(57%,#ff8600),color-stop(57%,#ae0000),color-stop(73%,#ae0000),color-stop(73%,#0065b1),color-stop(77%,#0065b1),color-stop(77%,#008520),to(#008520));
        background: linear-gradient(90deg,#e90a0a 0,#e90a0a 27%,#26221e 0,#26221e 32%,#ff8600 0,#ff8600 57%,#ae0000 0,#ae0000 73%,#0065b1 0,#0065b1 77%,#008520 0,#008520);
        height: 10px
    }

    .border,.box {
        display: block
    }

    .box {
        background-color: #fff;
        border: 2px solid #000;
        border-radius: 32px;
        -webkit-box-shadow: 0 0 46px 10px rgba(0,0,0,.1);
        box-shadow: 0 0 46px 10px rgba(0,0,0,.1);
        min-height: 320px;
        padding: 25px 25px 10px;
        width: 296px
    }

    @media screen and (max-width: 1200px) {
        .box {
            width:280px
        }
    }

    .box--1 {
        border-color: #e90a0a;
        -webkit-box-shadow: 0 0 46px 10px rgba(233,10,10,.1);
        box-shadow: 0 0 46px 10px rgba(233,10,10,.1)
    }

    .box--2 {
        border-color: #ff8600;
        -webkit-box-shadow: 0 0 46px 10px rgba(255,134,0,.1);
        box-shadow: 0 0 46px 10px rgba(255,134,0,.1)
    }

    .box--3 {
        border-color: #008520;
        -webkit-box-shadow: 0 0 46px 10px rgba(0,133,32,.1);
        box-shadow: 0 0 46px 10px rgba(0,133,32,.1)
    }

    .box__title {
        font-size: 20px;
        margin: 0 0 26px
    }

    .box__text {
        font-size: 18px
    }

    .box__img {
        height: 68px;
        margin: 0 auto 20px
    }

    .title {
        font-size: 30px;
        font-weight: 700;
        line-height: 1.15
    }

    @media screen and (max-width: 576px) {
        .title {
            font-size:25px
        }
    }

    .subtitle {
        font-size: 30px
    }

    @media screen and (max-width: 1200px) {
        .subtitle {
            font-size:25px
        }
    }

    @media screen and (max-width: 992px) {
        .subtitle {
            font-size:20px
        }
    }

    @media screen and (max-width: 768px) {
        .subtitle {
            font-size:18px
        }
    }

    .text {
        font-size: 22px;
        line-height: 1.15
    }

    @media screen and (max-width: 1200px) {
        .text {
            font-size:20px
        }
    }

    @media screen and (max-width: 992px) {
        .text {
            font-size:18px
        }
    }

    .documents {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -10px
    }

    @media screen and (max-width: 992px) {
        .documents {
            display:block;
            -ms-flex-wrap: unset;
            flex-wrap: unset;
            margin: 0
        }
    }

    .documents__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px;
        width: 50%
    }

    @media screen and (max-width: 992px) {
        .documents__item {
            padding:0;
            width: 100%
        }

        .documents__item:not(:last-of-type) {
            margin: 0 0 15px
        }
    }

    .documents__link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px
    }

    .documents__link:before {
        // background: url(./images/pdf.svg) 50%/26px 30px no-repeat;
        content: "";
        display: block;
        height: 30px;
        width: 26px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 0 15px 0 0
    }

    .documents__link--cookies:before {
        background: 0
    }

    .container {
        margin: 0 auto;
        max-width: 1015px;
        padding: 0 15px
    }
    .header{
        .container {
            position: relative;
            z-index: 1;
        }

    }

    @media screen and (max-width: 1200px) {
        .container {
            max-width:960px
        }
    }

    @media screen and (max-width: 992px) {
        .container {
            max-width:720px
        }
    }

    @media screen and (max-width: 768px) {
        .container {
            max-width:540px
        }
    }

    .header {
        height: 600px;
        position: relative
    }

    @media screen and (max-width: 992px) {
        .header {
            background-color:#fff;
            height: auto
        }
    }

    .header:after {
        background: url(./images/header_bg.jpg) 50% 50%/cover no-repeat #fff;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0
    }

    @media screen and (max-width: 992px) {
        .header:after {
            background-image:url(./images/header_bg_mobile.jpg);
            display: block;
            height: 350px;
            left: auto;
            margin: 30px 0 0;
            position: static;
            top: auto
        }
    }

    @media screen and (max-width: 768px) {
        .header:after {
            height:300px
        }
    }

    .header__text,.header__title {
        color: #fff
    }

    @media screen and (max-width: 992px) {
        .header__text,.header__title {
            color:#000
        }
    }

    .header__title {
        font-size: 52px;
        line-height: 1.2;
        margin: 0 0 15px;
        max-width: 600px
    }

    @media screen and (max-width: 992px) {
        .header__title {
            font-size:40px;
            margin: 0 0 10px;
            max-width: 450px
        }
    }

    @media screen and (max-width: 768px) {
        .header__title {
            font-size:30px;
            max-width: 350px
        }
    }

    .header__text {
        font-size: 30px;
        line-height: 1.2;
        margin: 0 0 30px;
        max-width: 450px
    }

    @media screen and (max-width: 992px) {
        .header__text {
            font-size:24px;
            margin: 0 0 20px;
            max-width: 400px
        }
    }

    @media screen and (max-width: 768px) {
        .header__text {
            font-size:20px;
            max-width: 300px
        }
    }

    .header__border {
        height: 12px;
        margin: 0 0 86px
    }

    @media screen and (max-width: 992px) {
        .header__border {
            height:10px;
            margin: 0 0 15px
        }
    }

    .footer {
        background-color: #fff;
        padding: 0 0 30px
    }

    .footer__container:before {
        background-color: #b2b2b2;
        content: "";
        display: block;
        height: 2px;
        margin: 0 0 30px
    }

    @media screen and (max-width: 576px) {
        .footer__container:before {
            margin:0 0 25px
        }
    }

    .footer__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 -10px 24px
    }

    @media screen and (max-width: 576px) {
        .footer__list {
            display:block;
            margin: 0 0 24px
        }
    }

    .footer__item {
        margin: 0 10px;
        a{
            color: #000 !important;
            font-weight: 400;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media screen and (max-width: 576px) {
        .footer__item {
            margin:10px 0
        }
    }

    .section {
        background-color: #fff
    }

    .benefits {
        padding: 85px 0 0
    }

    @media screen and (max-width: 992px) {
        .benefits {
            padding:40px 0 0
        }
    }

    @media screen and (max-width: 768px) {
        .benefits {
            padding:30px 0 0
        }
    }

    .benefits__title {
        margin: 0 0 18px
    }

    @media screen and (max-width: 992px) {
        .benefits__title {
            margin:0 auto 15px;
            max-width: 500px
        }
    }

    .benefits__subtitle {
        font-size: 24px;
        margin: 0 0 50px
    }

    @media screen and (max-width: 992px) {
        .benefits__subtitle {
            margin:0 0 30px
        }
    }

    @media screen and (max-width: 768px) {
        .benefits__subtitle {
            font-size:20px;
            margin: 0 0 25px
        }
    }

    .benefits__items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 0 42px
    }

    @media screen and (max-width: 992px) {
        .benefits__items {
            -webkit-box-orient:vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: unset;
            -ms-flex-pack: unset;
            justify-content: unset;
            margin: 0 0 5px
        }
    }

    .benefits__item {
        margin: 0 14px
    }

    @media screen and (max-width: 992px) {
        .benefits__item {
            margin:15px 0
        }
    }

    .benefits__btn {
        -webkit-transform: translateY(50%);
        transform: translateY(50%)
    }

    .offer {
        background-color: #f2f2f2;
        padding: 74px 0 42px
    }

    @media screen and (max-width: 992px) {
        .offer {
            padding:60px 0 30px
        }
    }

    @media screen and (max-width: 768px) {
        .offer {
            padding:55px 0 30px
        }
    }

    .offer__title {
        margin: 0 0 38px
    }

    @media screen and (max-width: 768px) {
        .offer__title {
            margin:0 auto 36px;
            max-width: 400px
        }
    }

    .offer__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 0 22px
    }

    @media screen and (max-width: 992px) {
        .offer__content {
            display:block
        }
    }

    .offer__img {
        margin: 0 66px 0 0;
        width: 210px
    }

    @media screen and (max-width: 992px) {
        .offer__img {
            margin:0 auto 40px
        }
    }

    @media screen and (max-width: 768px) {
        .offer__img {
            margin:0 auto 34px
        }
    }

    .offer__text {
        font-size: 18px;
        width: 265px
    }

    @media screen and (max-width: 992px) {
        .offer__text {
            margin:0 auto;
            max-width: 400px;
            text-align: center;
            width: auto
        }
    }

    .car {
        padding: 0 0 54px;
        h2 {
        a {
            color: #000 !important;
            font-weight: 700;
            &:hover {
            text-decoration: underline;
            }
        }
        }
    }

    @media screen and (max-width: 992px) {
        .car {
            padding:0 0 40px
        }
    }

    @media screen and (max-width: 576px) {
        .car {
            padding:0 0 35px
        }
    }

    .car__border {
        height: 12px;
        margin: 0 auto 56px;
        width: 222px
    }

    @media screen and (max-width: 992px) {
        .car__border {
            height:10px;
            margin: 0 auto 40px
        }
    }

    @media screen and (max-width: 576px) {
        .car__border {
            margin:0 auto 30px
        }
    }

    .car__title {
        margin: 0 0 10px
    }

    .car__text {
        font-size: 24px;
        margin: 0 auto 42px;
        max-width: 600px
    }

    @media screen and (max-width: 768px) {
        .car__text {
            font-size:20px;
            max-width: 400px
        }
    }

    @media screen and (max-width: 576px) {
        .car__text {
            margin:0 auto 30px;
            max-width: 280px
        }
    }

    .car__img {
        margin: 0 auto 50px
    }

    @media screen and (max-width: 992px) {
        .car__img {
            margin:0 auto 36px
        }
    }

    .overlay {
        background-color: #f2f2f2;
        padding: 35px 0
    }

    @media screen and (max-width: 576px) {
        .overlay {
            padding:30px 0
        }
    }

    .overlay__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    @media screen and (max-width: 576px) {
        .overlay__logo {
            height:40px
        }
    }

    .text-red {
        color: #e90a0a
    }

    .text-green {
        color: #008520
    }

    .text-blue {
        color: #0065b1
    }

    .text-orange {
        color: #ff8600
    }

    // .text-black {
        
    // }

    .text-left {
        text-align: left
    }

    .text-right {
        text-align: right
    }

    .text-center {
        text-align: center
    }

    .text-justify {
        text-align: justify
    }

    .text-uppercase {
        text-transform: uppercase
    }

    .text-lowercase {
        text-transform: lowercase
    }

    .text-capitalize {
        text-transform: capitalize
    }

    .font-weight-thin {
        font-weight: 100
    }

    .font-weight-extralight {
        font-weight: 200
    }

    .font-weight-light {
        font-weight: 300
    }

    .font-weight-regular {
        font-weight: 400
    }

    .font-weight-medium {
        font-weight: 500
    }

    .font-weight-semibold {
        font-weight: 600
    }

    .font-weight-bold {
        font-weight: 700
    }

    .font-weight-extrabold {
        font-weight: 800
    }

    .font-weight-black {
        font-weight: 900
    }

    .letter-spacing {
        letter-spacing: -.04em
    }

}