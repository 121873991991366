.certificate-widget-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 600px;

    .certificate-widget-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none
    }

    .certificate-widget-button {
        display: flex;
        gap: 16px;
        max-width: 1220px;
        margin: auto;
        padding: 0 0 32px 16px;
    }
}

@media screen and (max-width: 1024px) {
    .certificate-widget-container {

        .certificate-widget-button {
            display: flex;
            flex-direction: column-reverse;
            gap: 32px;
            align-items: center;
            justify-content: center;
            padding: 32px 0;

            a {
                button {
                    width: 240px;
                }
            }
        }
    }
}