@import "./src/assets/functions/get-color";

.tooltip-container {
  display: flex;
  align-items: center;
  margin-left: 8px;

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    .tooltip-text-container {
      display: block;
    }
  }

  &.dark {
    .tooltip-text-container {
      background-color: dark-mode-property("secondary-background");
    }
  }

  .tooltip-text-container {
    position: absolute;
    display: none;
    background-color: light-mode-property("secondary-background");
    padding: 20px;
    width: 375px;
    height: auto;
    left: 0px;
    bottom: 40px;
    z-index: 10;

    .title {
      display: flex;
      margin-bottom: 16px;

      p {
        margin-left: 12px;
      }
    }
    p {
      font-size: 12px;
    }
  }
}
@media (max-width: 1023px) {
  .tooltip-container .tooltip-text-container {
    width: 100%;
  }
}
