@import "../../../assets/styles/variables";

.cs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 72px 0;

  .section-title-container {
    margin-bottom: 32px;
  }

  .cars-container {
    display: flex;
    padding: 0 $paddingDesktop;
    overflow: hidden;
    width: 100%;

    .car-offer-wrapper {
      height: 100%;

      a[data-component="Tile"],
      div[data-component="Tile"] {
        padding: 0;
        height: 100%;
      }
    }

    > [data-component="Box"] {
      width: 100%;
    }

    .service-card-tile {
      height: 100%;
      background-color: #fff;
      padding: 32px;
      position: relative;
      flex-shrink: 0;
      border-radius: 32px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      svg {
        width: 222px;
        height: 222px;
        margin-bottom: 24px;
      }
    }

    .swiper {
      overflow: unset !important;

      .swiper-wrapper {
        .swiper-slide {
          height: auto;

          .cars-container {
            height: 100%;
          }
        }
      }
    }
  }

  .paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 28px;
    margin-bottom: 20px;
    margin-top: 10px;

    .paginator-buttons {
      display: flex;
      align-items: center;
      padding: 0 90px;

      button {
        width: 6px;
        height: 6px;
        background-color: #0065b1;
        border: none;
        border-radius: 0;
        opacity: 0.3;
        outline: none;
        padding: 0;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 8px;
        }

        &.active {
          width: 18px;
          opacity: 1;
        }
      }
    }
  }

  .cars-section-button {
    margin-top: 24px;
  }
}

.cs-container-white {
  background-color: #ffffff;
  .cars-container {
    .service-card-tile {
      background-color: #F6F6F6;
    }
  }
}

.cs-container-wild-sand-grey {
  background-color: #F6F6F6;
}

@media (max-width: 1023px) {
  .cs-container {
    padding: 24px 0;

    .section-title-container {
      margin-bottom: 24px;
    }

    .cars-container {
      padding: 0 $paddingMobile;

      .service-card-tile {
        padding: 24px;

        svg {
          height: 216px;
          width: 216px;
          margin-bottom: 16px;
        }

        h2 {
          font-size: 20px;
        }

        p {
          margin-bottom: 24px;
        }
      }
    }

    .cars-section-button {
      width: min-content;
    }

    div[data-component="Box"] button[data-component="IconButton"] {
      margin-top: 16px;
    }

    .paginator {
      width: 100%;
    }
  }
}
