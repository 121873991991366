.mobile-list-baner-navbar {
  display: none;
}

@media (max-width: 1023px) {
  .mobile-list-baner-navbar {
    display: flex;
    width: 100%;
    height: 60px;
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.07);
    padding-left: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    transition: all 0.3s;

    &.scroll-top {
      box-shadow: none;
    }

    .nav_bar-mobile-button-container {
      margin-right: 10px;
      padding-top: 10px;
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
}