@font-face {
  font-family: 'mBank';
  font-weight: normal;
  font-display: swap;
  font-style: normal;
  src: local('mBank'), local('mBank-Regular'), url('assets/fonts/mBank/mBank-Regular.eot');
  src: local('mBank'), url('assets/fonts/mBank/mBank-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/mBank/mBank-Regular.woff2') format('woff2'),
    url('assets/fonts/mBank/mBank-Regular.woff') format('woff'),
    url('assets/fonts/mBank/mBank-Regular.ttf') format('truetype'),
    url('assets/fonts/mBank/mBank-Regular.svg#mBank-Regular') format('svg');
}

@font-face {
  font-family: 'mBank';
  font-weight: bold;
  font-display: swap;
  font-style: normal;
  src: local('mBank Bold'), local('mBank-Bold'), url('assets/fonts/mBank/mBank-Bold.eot');
  src: local('mBank Bold'), local('mBank-Bold'),
    url('assets/fonts/mBank/mBank-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/mBank/mBank-Bold.woff2') format('woff2'),
    url('assets/fonts/mBank/mBank-Bold.woff') format('woff'),
    url('assets/fonts/mBank/mBank-Bold.ttf') format('truetype'),
    url('assets/fonts/mBank/mBank-Bold.svg#mBank-Bold') format('svg');
}

@font-face {
  font-family: 'mBank';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: local('mBank Semi Bold'), local('mBank-SemiBold'), url('assets/fonts/mBank/mBank-SemiBold.eot');
  src: local('mBank Semi Bold'), local('mBank-SemiBold'),
    url('assets/fonts/mBank/mBank-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/mBank/mBank-SemiBold.woff2') format('woff2'),
    url('assets/fonts/mBank/mBank-SemiBold.woff') format('woff'),
    url('assets/fonts/mBank/mBank-SemiBold.ttf') format('truetype'),
    url('assets/fonts/mBank/mBank-SemiBold.svg#mBank-SemiBold') format('svg');
}

@font-face {
  font-family: 'mBank';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: local('mBank Semi Light'), local('mBank-Light'), url('assets/fonts/mBank/mBank-Light.eot');
  src: local('mBank Semi Light'), local('mBank-Light'),
    url('assets/fonts/mBank/mBank-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/mBank/mBank-Light.woff2') format('woff2'),
    url('assets/fonts/mBank/mBank-Light.woff') format('woff'),
    url('assets/fonts/mBank/mBank-Light.ttf') format('truetype'),
    url('assets/fonts/mBank/mBank-Light.svg#mBank-Light') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat Thin'), local('Montserrat-Thin'), url('assets/fonts/Montserrat/Montserrat-Thin.eot');
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('assets/fonts/Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-Thin.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat Light'), local('Montserrat-Light'), url('assets/fonts/Montserrat/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('assets/fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-Light.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-Light.svg#Montserrat-Light') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('assets/fonts/Montserrat/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat'), local('Montserrat-Regular'), url('assets/fonts/Montserrat/Montserrat-Regular.eot');
  src: local('Montserrat'), local('Montserrat-Regular'),
    url('assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('assets/fonts/Montserrat/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat Black'), local('Montserrat-Black'), url('assets/fonts/Montserrat/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('assets/fonts/Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-Black.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('assets/fonts/Montserrat/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('assets/fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
    url('assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'),
    url('assets/fonts/Montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
}