.promo-banner {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
  border: 1.5px solid #efd063;
  display: flex;
  margin-bottom: 40px;
  &__title {
    span{
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1023px) {
  .promo-banner {
    justify-content: center;
    &__title,
    &__subtitle {
      text-align: center;
    }
    &__button {
      display: flex;
      justify-content: center;
    }
    &__right {
      display: none;
    }
  }
}
