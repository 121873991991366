@import "../../../assets/functions/get-color";

.contact-form-section-modal-container {
  padding: 32px;
  max-width: 559px;

  &.dark {
    background-color: dark-mode-property("primary-background");
  }

  .title {
    width: 100%;
    max-width: 680px;
  }

  .contact-form__link-button {
    color: #000000;
    font-weight: 700;
  }

  .checkbox-container {
    position: relative;
    margin-bottom: 40px;
    &__extend-text-button {
      position: absolute;
      bottom: -15px;
      left: 35px;
      font-size: 12px;
      font-weight: 700;
      color: #0065B1;
      text-decoration: underline;
      cursor: pointer;
    }
    span {
      line-height: 16px;
    }
    &--last {
      margin-bottom: 40px;
    }
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div[data-component="Stack"] {
      width: 100%;
      margin-bottom: 16px;

      & > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        justify-content: space-between;

        & > div {
          width: calc(50% - 12px);
          margin-left: 0;
        }
      }
    }

    & > div:nth-of-type(3) {
      & > div {
        width: 100%;
      }
    }

    & > div[data-component="Checkbox"] {
      margin-bottom: 16px;
    }

    & > div[data-component="TextField"] {
      margin: 0 0 20px;
    }

    //.textarea {
    //  width: 100%;
    //  margin-bottom: 24px;
    //}
  }

  .contact-form-section__logo-container {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .logo-container__line {
      height: 40px;
      width: 2px;
      background-color: #20202086;
      margin: 0 8px;
      display: none;
    }
    .logo-container__text {
      display: none;
    }
    svg {
      height: 40px;
      width: 120px;
    }
  }

  .contact-form-section__text-container {
    br {
      display: none;
    }
  }
  .form--isSpecialLandingPage {
    justify-content: center;
    & > div[data-component="Stack"] {
      margin-bottom: 8px;
    }
    div[data-component="Stack"] {
      width: 100%;
      padding-top: 8px;
    }
  }

  .recaptcha {
    margin: auto;
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }
  .trade-information {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .contact-form-section-container {
    .buttons-container {
      // width: 240px;
      justify-content: center;
      margin: auto;
      padding-top: 16px;
    }

    form {
      & > div[data-component="Stack"] {
        & > div {
          & > div {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .contact-form-section-container {
    padding: 16px 8px;
    .contact-form-section__text-container {
      br {
        display: block;
      }
    }
    .contact-form-section__logo-container {
      .logo-container__line {
        display: block;
      }
      .logo-container__text {
        display: block;
      }
    }
  }
}
