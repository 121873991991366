@import "../../../assets/styles/variables";

.list-page-container {
  position: relative;
  .contact-section-container {
    background-color: #f6f6f6;

    .contact-section-paper {
      margin: 10px 0 0;
    }
  }

  .sponsored {
    background-color: #efd063;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    .sponsored-wrapper {
      max-width: 1330px;
    }
  }
  .offer-wrapper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px auto;
    padding: 0 $paddingDesktop;

    .offer-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: $containerWidth;
      gap: 24px;

      .no-auctions-info-section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 48px;
      }

      & > div,
      & > a {
        padding: 0;
        width: 420px;
      }

      .no-cars-set-alert-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;

        .title,
        .content {
          padding-bottom: 24px;
          max-width: 640px;

          .link {
            cursor: pointer;
          }
        }
      }
    }
  }
  .show-more-button {
    margin-top: 20px;
  }

  .mobile-header {
    @media (min-width: 1024px) {
      display: none;
    }
  }
  .not-mobile-header {
    @media (max-width: 1023px) {
      display: none;
    }
  }
}

@media screen and (max-width: 1330px) {
  .list-page-container {
    .offer-wrapper {
      max-width: 880px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .list-page-container {
    .contact-section-container {
      .contact-section-paper {
        margin: 16px 0 0;
      }
    }

    .sponsored {
      flex-wrap: wrap;
      justify-content: space-around;
      height: auto;
      & > div,
      [data-component="Tile"] {
        margin: 20px 0px;
        padding: 0;
        width: 100%;
      }

      .sponsored-wrapper {
        & > a,
        & > div {
          width: 100%;
          margin: 12px 0;
        }
      }
    }

    .offer-wrapper {
      justify-content: space-around;
      width: 319px;
      & > div,
      a {
        margin: 12px 0px;
        width: 100%;
      }
      .specific-card-tile {
        max-width: 319px;
      }
    }
    .nav_bar-mobile-modal-container {
      position: fixed;
      padding-top: 0;
      z-index: $z-index-filters-mobile;
      .lb-container {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        background: #ffffff;
      }
    }
  }
}
