@import "../../../assets/functions/get-color";
@import "../../../assets/styles/variables";

.footer-container {
  background-color: #f6f6f6;

  &.dark {
    background-color: dark-mode-property("primary-background");
  }

  .footer-wrapper {
    width: 100%;
    padding: 32px $paddingDesktop;

    & > div {
      > [data-component="HorizontalLine"] {
        margin: $paddingDesktop 0;
      }
    }
  }

  .contact-container {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    margin-bottom: 24px;

    &__item {
      flex: 1;
      display: flex;
      align-items: center;

      &-icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        background-color: #f6f6f6;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a {
        color: unset;
      }
    }
    &__item-contact {
      cursor: pointer;
    }
  }

  .links-container {
    display: flex;
    justify-content: space-between;

    .page-links {
      display: flex;

      a {
        color: #6e6e6e;
        text-decoration: underline;
      }

      .mobile-break {
        display: none;
      }
    }
  }

  .bottom-container {
    display: flex;
    justify-content: space-between;

    .bottom-container__left {
      display: flex;
      align-items: center;

      .bottom-container__logo {
        width: 96px;
        margin-right: 40px;
      }
    }

    .bottom-container__right {
      .bottom-container__icons {
        display: flex;
        .icon-container {
          height: 48px;
          width: 48px;
          border-radius: 50%;
          border: 1.5px solid #d8d8d8;
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-child {
            margin-right: 16px;
          }
        }
        .icon {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          &.facebook {
            background-image: url("../../../assets/icons/facebook.svg");
            width: 22px;
            height: 22px;
          }

          &.instagram {
            background-image: url("../../../assets/icons/instagram.svg");
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.app-data-container {
  margin: 60px;

  p {
    font-size: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .footer-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .footer-wrapper {
      padding: 24px $paddingMobile;

      & > div {
        > [data-component="HorizontalLine"] {
          margin: 24px 0;
        }
      }
    }

    .contact-container {
      padding: 16px;
      flex-direction: column;
      gap: 16px;
    }

    .links-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;

      .page-links {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        margin-bottom: 12px;

        a:not(:first-of-type) {
          margin-top: 24px;
        }

        .mobile-break {
          display: inherit;
        }

        p {
          width: 100%;
          margin-bottom: 24px;

          a {
            margin-right: 0;
          }
        }
      }
    }

    .bottom-container {
      flex-direction: column;
      align-items: center;
      .bottom-container__left {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 24px;

        .bottom-container__logo {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .footer-container {
    .bottom-container {
      .bottom-container__left {
        flex-direction: column;
        .bottom-container__logo {
          margin-bottom: 16px;
        }
      }
    }
  }
}
