.special-promotions {
  position: absolute;
  display: flex;
  gap: 8px;
  left: 32px;
  top: 32px;
  z-index: 1;

  .promotion {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    color: white;

    svg {
      margin-right: 8px;
    }
  }
}