.observe-vehicle-button {
  &.active {
    svg {
      path {
        fill: #0065b1;
      }
    }
    &.mobile-icon{
      svg {
        path {
          fill: #D21313;
        }
      }
    }
  }
}