@import "../../../assets/functions/get-color";
@import "../../../assets/styles/variables";

.nav-bar-container-with-stripes {
  position: relative;
  background: #ffffff;
  z-index: $z-index-navbar;

  &.dark {
    background-color: dark-mode-property("primary-background");
  }

  hr {
    position: absolute; //(z-index seems to be not working on that element without position: absolute)
    z-index: $z-index-navbar-hr;
    left: 0;
  }

  .nav_bar-container {
    width: 100%;
    padding: 22px $paddingDesktop;
    z-index: $z-index-menu;
    background-color: white;

    .navbar-wrapper {
      max-width: $containerWidth;
      margin: 0 auto;
    }

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .nav_bar-phone-container {
      position: absolute;
      top: 20px;
      right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .nav_bar-container-logo-and-links {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &.dark {
        background-color: dark-mode-property("primary-background");
      }
    }

    .nav-bar-logo-anchor {
      height: 50px;
    }

    .nav_bar-logo {
      height: 100%;
      margin-right: 73px;
    }

    .nav_bar-links-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .nav_bar-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;

        .buttons {
          & > * {
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }

        .highlighted {
          border-bottom-color: #0065B1;
        }

        a {
          text-decoration: none;

          p {
            border-bottom: 2px solid transparent;
          }
        }
      }

      .nav_bar-sublinks-container {
        position: relative;
        left: -224px;
        margin-top: 36px;
        display: flex;
        min-height: 240px;
      }
    }

    .nav_bar-mobile-button-container {
      display: none;

      & > span {
        color: #000000;
      }
    }
  }

  .nav_bar-sublinks-background {
    z-index: 9900;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .last-seen-cars-container {
    position: absolute;
    background-color: white;
    height: 320px;
    z-index: 200;
    bottom: -310px;
    right: 40px;
    width: 700px;
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.08);
    overflow-y: auto;

    .information-header {
      display: none;
      flex-direction: column;
      width: 100%;
      padding: 20px 16px 0;

      .action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
      }
    }

    & > div {
      margin-bottom: 24px;
    }
  }
}

.nothing-in-view-history {
  padding: 20px;
}

@media screen and (max-width: 1280px) {
  .nav-bar-container-with-stripes {
    .nav_bar-container {
      .nav-bar-logo-anchor {
        .nav_bar-logo {
          width: initial;
        }
      }

      .nav_bar-container-logo-and-links {
        .nav_bar-links-container {
          .nav_bar-sublinks-container {
            max-width: 100%;
            overflow-x: auto;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .nav-bar-container-with-stripes {
    .nav_bar-container {
      .nav_bar-container-logo-and-links {
        .nav-bar-logo-anchor {
          svg {
            margin-right: 43px;
          }
        }

        .nav_bar-sublinks-container {
          left: -192px;
        }
        .nav_bar-links-container {
          .nav_bar-links {
            .links {
              & > * > * {
                margin-right: 12px;
                white-space: nowrap;
              }
            }

            .buttons {
              flex-wrap: nowrap;

              & > * {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .nav-bar-container-with-stripes {
    height: auto;
    position: sticky;
    top: 0;
    z-index: $z-index-navbar-mobile;

    hr {
      height: 6px;
    }

    .nav_bar-container {
      z-index: 15;
    }

    .last-seen-cars-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      & > div {
        margin-bottom: 0;
      }

      .information-header {
        display: flex;
      }
    }

    .nav_bar-container {
      padding: 19px $paddingMobile 10px;

      .nav_bar-container-logo-and-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
      }

      .nav_bar-navigations-buttons-container {
        display: flex;
        gap: 26px;
      }

      .nav_bar-links-container {
        display: none;
      }

      .nav_bar-phone-container,
      .nav_bar-links {
        display: none;
      }

      .nav_bar-logo {
        width: 100%;
        max-width: 84px;
      }

      .nav-bar-logo-anchor {
        width: initial;
        margin-bottom: 0px;
        display: flex;
        align-content: center;
        height: initial;
      }

      .nav_bar-mobile-button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        position: relative;
        color: #26221e;

        .filters-count {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          color: #ffffff;
          top: 6px;
          right: 0px;
          position: absolute;
          background: #ae0000;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 8px;
          }
        }

        .viewed-cars-count-circle {
          justify-content: center;
          width: 11px;
          height: 11px;
          top: 6px;

          span {
            font-size: 8px;
            line-height: 11px;
          }
        }
      }
      .fav-nav-button{
        &__fav-number{
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color:#ae0000;
          border-radius: 50%;
          height: 16px;
          width: 16px;position: absolute;
          z-index: 2;
          top: -4px;
          right: -8px;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }

    .nav_bar-mobile-modal-container {
      position: fixed;
      z-index: $z-index-menu-mobile;
      background: #ffffffff;
      width: 100%;
      padding-top: 0;
      overflow: scroll;

      &.mobile-hamburger-menu {
        background-color: #f6f6f6;
        .nav_bar-mobile-modal-title-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            padding: 14px 16px;
            width: auto;
          }

          .clickable-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 14px 16px;
          }
        }

        .links {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          a:last-child {
            border-bottom: none;
          }

          a,
          .clickable {
            padding: 12px 0;
            margin-left: 16px;
            border-bottom: 1px solid #d8d8d8;
            width: calc(100% - 32px);

            p {
              text-align: start;
              padding: 0;
            }

            div {
              margin: unset;
            }
            .link-with-arrow {
              justify-content: space-between;
            }
          }

          .clickable > p {
            justify-content: space-between;

            .rotable {
              transform: rotate(270deg);
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          a {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              width: 40%;
              margin: 0;
              margin-top: 16px;
            }
          }
        }
        .return-button-container {
          background-color: #fff;
          h3 {
            font-size: 1rem;
          }
        }
        .offer-sublinks {
          flex-direction: column;

          a > p {
            justify-content: flex-start;
          }

          .as-link > p {
            padding-left: 8px;
          }
          .sublinks-column {
            margin-bottom: 12px;
            & > *:last-child {
              border: none;
            }
            &-header {
              width: calc(100% - 32px);
              margin: 16px 16px 0;
              border-bottom: 1px solid #d8d8d8;
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > p {
                margin: 0;
              }
              .sublinks-column__link {
                border: none;
                width: unset;
                .sublinks-column__text {
                  margin: 0;
                }
              }
            }
            width: 100%;
            a,
            .clickable {
              padding: 0;
              margin: 0;
              margin-left: 16px;
              border-bottom: 1px solid #d8d8d8;
              width: calc(100% - 32px);
            }

            p,
            .clickable {
              padding: 12px 0;

              p {
                padding: 0;
              }
            }
          }
        }

        .brand-sublinks {
          max-height: unset;
          width: 100%;
          flex-wrap: nowrap;
          a,
          .clickable {
            padding: 0;
            margin: 0;
            margin-left: 16px;
            border-bottom: 1px solid #d8d8d8;
            width: calc(100% - 32px);
            display: flex;
            justify-content: flex-start;
          }

          .as-link > p {
            padding-left: 8px;
          }

          p,
          .clickable {
            padding: 12px 0;
            display: flex;
            justify-content: flex-start;

            p {
              padding: 0;
            }
          }
        }

        .viewed-cars-button-container {
          width: fit-content;
          margin: auto;
          // font-weight: 600;

          p {
            padding: 12px 0;
            margin-right: 30px;
          }

          //.viewed-cars-count-circle {
          //  // top: 0;
          //
          //  p {
          //    margin-right: 30px;
          //  }
          //}
        }
      }
    }
  }
}
