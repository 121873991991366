@import '../../../assets/functions/get-color';
@import "../../../assets/styles/variables";

.details-navigation {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #D8D8D8;
  z-index: 1;
  overflow: hidden;
  padding: 16px $paddingDesktop;

  .details-list {
    display: flex;
    list-style: none;
    margin: 0;
    overflow-x: auto;

    a {
      padding: 8px 0 6px;
      color: light-mode-property('color-zeus-black-ui');
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      transition: all 0.1s ease-in-out 0.2s;
      border-bottom: 2px solid transparent;
      white-space: nowrap;

      &.active {
        border-color: #0065B1;
        color: #0065B1;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .details-navigation {
    top: 57px;
    padding: 8px $paddingMobile;

    .details-list {
      a {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}