.vehicle-gallery {
  position: relative;

  .swiper {
    .swiper-pagination {
      display: none;
    }

    > [data-component="Box"] {
      display: none;
    }
  }

  .carousel,
  .virtual-car-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 24px;

    &.hidden {
      opacity: 0;
      z-index: -1;
      height: 0;
      padding: 0;
      margin: 0;
    }
  }

  .virtual-car-container {
    position: relative;
    height: 487px;

    .inner-player {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .carousel {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    max-height: 487px;

    button[type="button"] {
      > span {
        &:hover,
        &:active {
          background-color: transparent;
        }
      }

      svg {
        path {
          stroke: #ffffff;
        }
      }
    }

    .pagination {
      .button-left,
      .button-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        border: 0;
        background: #6e6e6e;
      }

      .button-left {
        left: 16px;
      }

      .button-right {
        right: 16px;
      }
    }

    .slide {
      background-color: #f6f6f6;
      background-size: cover;

      picture {
        display: flex;

        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }
    .button-close-container {
      position: absolute;
      top: 24px;
      right: 102px;
      z-index: 2;
      padding: 8px 2px 8px 8px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background: #6e6e6e;
      .button-close {
        position: relative;
      }
    }
  }

  .thumbnails {
    display: flex;
    width: 100%;
    margin-bottom: 21px;

    .thumbnail {
      display: flex;
      align-items: center;
      height: 90px;
      cursor: pointer;
      overflow: hidden;
      border-radius: 16px;
      box-sizing: border-box;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 4px solid transparent;
        border-radius: 16px;
      }

      &.active {
        &:after {
          border-color: #0065b1;
        }
      }

      picture {
        display: flex;
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &:not(:first-of-type) {
        margin-left: 24px;
      }
    }
  }
  
  .promo-banner-container--web{
    display: block;
  }

}

@media screen and (max-width: 1023px) {
  .vehicle-gallery {
    .carousel {
      margin-bottom: 16px;
      border-radius: unset;
      height: auto;

      .pagination {
        display: none;
      }

      .button-close-container {
        padding: 8px;
        border-radius: 24px;
        right: 8px;
        top: 8px;
      }
    }

    .thumbnails {
      padding: 0 16px;
      margin-bottom: 16px;
      overflow: hidden;

      .thumbnail {
        border-radius: 12px;
        height: 75px;

        &:after {
          border-width: 2px;
          border-radius: 12px;
        }
      }

      .swiper {
        overflow: unset;
      }
    }

    .virtual-car-container {
      padding-bottom: 56%;
      height: auto;
      margin-bottom: 16px;
    }

    .promo-banner-container--web{
      display: none;
    }
  }
}
