@import "../../../assets/functions/get-color";

.characteristic-features-list-container {
  margin-top: 40px;
  margin-bottom: 32px;

  &.dark {
    .list-container {
      ul {
        li {
          p {
            color: dark-mode-property("color");
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 32px;
  }

  .show-more-button {
    display: flex;
    cursor: pointer;
    color: #0065b1;
    font-weight: 600;

    svg {
      margin-left: 10px;
    }
  }

  .list-container {
    display: flex;
    width: 100%;

    ul {
      width: 100%;
      margin-top: 0;

      display: grid;
      // gdzie tooo
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding-left: 0;
      gap: 24px;
      .technical-information {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .technical-information__key {
          padding-right: 10px;
        }
      }

      li {
        color: #26221e;
        font-size: 24px;
        list-style-type: none;
        break-inside: avoid;

        &.no-dot-point {
          list-style-type: none;
        }

        a {
          p {
            &:only-child {
              padding-right: 0;
            }
          }
        }

        p {
          &:only-child {
            padding-right: 4px;
          }
        }
      }
    }

    ul.list-with-one-item {
      column-count: 1;
      list-style-type: none;
      margin-bottom: 0;
      .technical-information {
        justify-content: flex-start;
      }
      li {
        list-style-type: none;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .characteristic-features-list-container {
    margin-top: 24px;
    margin-bottom: 32px;

    .list-container {
      flex-direction: column;

      ul {
        padding-left: 32px;
        grid-template-columns: 1fr;
        padding-left: 0;
        gap: 16px 0;

        li {
          width: 100%;

          .technical-information {
            &.with-url {
              & > p {
                text-align: left;
              }
            }

            span {
              &:first-of-type {
                text-align: left;
              }
            }
          }
        }
      }
    }

    .show-more-button {
      margin-top: 16px;
    }
  }
}
