@import "./../variables";

.question-text-section-container {
  padding: 14px $paddingDesktop 0;

  & > * {
    max-width: $containerWidth;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1023px) {
    padding: 10px $paddingMobile 10px;
  }

  h5 {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  p {
    margin-top: 16px;
  }
  
  b,
  strong {
    font-weight: 700;
  }
}