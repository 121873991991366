.additional-packages-section {
  padding: 40px 0;

  .additional-packages-tiles-section {
    display: flex;
    flex-direction: column;

    .additional-package-tile-container {
      width: 415px;
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 1420px) {
  .additional-packages-section {
    width:80%;
  }
}

@media screen and (max-width: 1023px) {
  .additional-packages-section {
    position: relative;
    padding: 24px 0 0 0;
    width: 100%;
    z-index: 0;
  }
}
