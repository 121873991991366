.information-tile-container {
  & > div {
    box-shadow: none;
  }

  .tile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;

    &.general {

      .title,
      .description {
        margin-bottom: 8px;
        font-family: mBank, Arial, sans-serif;
      }

      .title {
        max-width: 250px;
      }

      .description {
        margin-bottom: 8px;
      }

      svg {
        width: 48px;
        height: 48px;
        margin-bottom: 13px;
      }
    }

    &.long {
      .title,
      .description {
        margin-bottom: 24px;
      }
    }
  }

  ul {
    li {
      list-style-type: square;
      color: #008520;

      p {
        color: #201c17;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .information-tile-container {
    .tile-wrapper {
      align-items: center;
      text-align: center;

      .button-container {
        display: flex;
        justify-content: center;
      }
    }
  }
}
