.contact-form-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;
  .contact-form-modal {
    width: 80%;
    max-width: 1194px;
    background-color: #fff;
    border-radius: 16px;
    padding: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact-form-modal__exit {
      position: absolute;
      right: 28px;
      top: 28px;
      cursor: pointer;
    }
    .contact-form-modal__form-image-container {
      display: flex;
      width: 100%;
      padding: 24px;

      .contact-form-modal__form-container {
        flex: 1;
        margin-right: 12px;
      }

      .contact-form-modal__image-tile-container {
        flex: 1;
        margin-left: 12px;

        .image-tile {
          width: 364px;
          border-radius: 24px;
          background-color: #F6F6F6;
          overflow: hidden;
          margin: 0 auto;
          .image-tile__top {
            position: relative;
            .image-tile__shadow {
              background-color: rgba(216, 216, 216, 0.5);
              height: 176px;
              position: absolute;
              width: 100%;
              height: 176px;
              z-index: 5;
            }
            .image-tile__icon {
              position: absolute;
              z-index: 6;
              top: calc(50% - 32px);
              left: calc(50% - 32px);
            }
          }
          .image-tile__bottom {
            padding: 24px 24px 20px 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .contact-form-modal-container {
    z-index: 16;
    align-items: flex-start;
    overflow: scroll;
    background-color: #fff;
    .contact-form-modal {
      width: 100%;
      border-radius: 0;

      & > .contact-form-modal__title {
        text-align: center;
        margin: 0 48px 16px 48px;
      }
      .contact-form-modal__form-image-container {
        flex-direction: column-reverse;
        align-items: center;
        padding: 0;
        .contact-form-modal__form-container {
          margin-right: 0;
          .contact-form-section-container {
            padding: 16px 0;
          }
        }
        .contact-form-modal__image-tile-container {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-form-modal-container {
    .contact-form-modal {
      .contact-form-modal__form-image-container {
        .contact-form-modal__image-tile-container {
          .image-tile {
            width: 327px;
            max-width: 95%;
          }
        }
      }
    }
  }
}
