.contact-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  background-color: #f6f6f6;
  width: 343px;
  margin: 42px 0 0 0;
  align-self: center;
  min-width: 260px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__divider hr{
    position: relative;
    height: 1px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  hr {
    margin: 24px 0;
  }
}

@media (max-width: 1023px) {
  .contact-card {
    background-color: #fff;
    width: calc(100% - 32px);
    margin: 42px 16px 0;

    &__header {
      gap: 0px;
    }

    &__buttons {
      button {
        display: flex;
        gap: 12px;
        width: 100% !important;
        margin: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
}
