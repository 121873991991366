.promoted-offer-sticker {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 4px;;

  .star-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../assets/icons/star.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
  }
}
