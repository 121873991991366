.cdit-container {
    display: flex;
    align-items: center;
    margin-right: 8px;

    svg {
        margin-right: 8px;
    }
}

@media screen and (max-width: 520px) {
    .cdit-container {
        margin-right: 2px;

        svg {
            margin-right: 4px;
        }
    }
}