.specific-card-tile {
  padding: 32px !important;
  position: relative;
  flex-shrink: 0;
  border-radius: 32px;
  margin: 0;
  display: flex;
  align-items: start;
  justify-content: end;
  flex-direction: column;
  min-height: 100%;
  height: 533px;

  svg {
    width: 222px;
    height: 222px;
    margin-bottom: 24px;
  }
}
.tile-type-1 {
  background-image: url("./img/tile_background_1.jpg");
  background-size: 200%;
  background-position: 30% 50%;
}
.tile-type-2 {
  background-image: url("./img/tile_background_2.png");
  background-size: cover;
}
.tile-type-3 {
  background-image: url("./img/tile_background_3.jpg");
  background-size: cover;
  background-position: 0% 67%;
}
@media (max-width: 1023px) {
  .specific-card-tile {
    padding: 24px;
    svg {
      height: 216px;
      width: 216px;
      margin-bottom: 16px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      margin-bottom: 24px;
    }
  }
  .tile-type-1 {
    background-size: cover;
  }
}
