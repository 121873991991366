@keyframes appear {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.offer-sublinks {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 800px;
  animation: 0.4s ease-out 0s 1 appear;

  .sublinks-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    a {
      margin: 8px 0;
    }
    .offer-sublinks__show-all-link {
      text-decoration: underline;
    }
  }

  .grey-banner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .grey-banner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      background-color: #f6f6f6;

      .grey-banner-header {
        margin-bottom: 24px;
      }

      .grey-banner-link {
        margin-bottom: 8px;
        cursor: pointer;

        svg {
          margin-right: 4px;
        }
      }

      .centered {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.brand-sublinks {
  display: flex;
  justify-content: space-between;
  min-height: 240px;
  max-height: 312px;
  width: 100%;
  animation: 0.4s ease-out 0s 1 appear;
  overflow-x: hidden;
  .column {
    margin-right: 8px;
    p {
      margin: 12px 0;
      text-transform: capitalize;
    }
  }
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.as-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
}

@media screen and (max-width: 1023px) {
  .offer-sublinks {
    width: 100%;
    .grey-banner-container {
      width: 100%;
      margin-top: 16px;

      .grey-banner {
        width: 100%;
        align-items: center;
      }
    }
  }
  .brand-sublinks {
    flex-direction: column;
    .column {
      p {
        margin: 0;
      }
    }
  }

  .brand-sublinks {
    min-height: unset;
}}
