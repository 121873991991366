.car-offer-tile {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .car-offer-tile__shadow {
    background-color: rgba(255, 255, 255, 0.65);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .car-image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 224px;
    position: relative;
    overflow: hidden;
    border-radius: 32px 32px 0 0;

    .car-image {
      position: absolute;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .icon {
      position: absolute;
      z-index: 1;
      right: 32px;
      top: 32px;
    }

    .special-promotions {
      left: 32px;
      top: 32px;
      z-index: 1;
    }

    .upper-container {
      display: flex;
      justify-content: space-between;
      position: relative;

      .left-wing {
        padding: 16px 0 0 20px;
      }
    }

    .lower-container {
      display: flex;
      justify-content: space-between;
      position: relative;

      .left-wing {
        display: flex;
        flex-direction: column;
        padding: 0 0 16px 20px;

        & > span {
          width: fit-content;

          &:not(:last-of-type) {
            margin-bottom: 4px;
          }
        }
      }
    }
    .sold-car-buttons {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      .sold-car-buttons__top {
        position: absolute;
        top: 26px;
        left: 32px;
        padding: 8px;
        background-color: #6e6e6e;
        border-radius: 8px;
        p {
          margin: 0;
        }
      }
      .sold-car-buttons__bottom {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 16px 32px;
        display: flex;
        justify-content: flex-end;
        gap: 32px;
      }
    }
  }

  .car-informations-container {
    padding: 24px 32px 32px 32px;
    display: flex;
    flex: 1;

    .left-wing {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.decreased-width {
        display: flex;
        flex-direction: column;

        .tile-header {
          flex: 1;
        }

        .left-wing-labels {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          padding-bottom: 8px;
        }
      }

      .special-discount-container {
        max-width: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 64px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .all-prices-container {
        display: flex;
        overflow: hidden;
        & > div {
          width: 33%;
        }
        &.remove-margin-right {
          .price-container {
            &:not(:last-of-type) {
              margin-right: unset;
            }
          }
        }

        .price-container {
          & > div:not(:last-child) {
            margin-right: 40px;
          }

          .price {
            display: flex;
            align-items: baseline;
            flex-direction: column;
          }
        }

        & > *:nth-child(4) {
          @media screen and (max-width: 520px) {
            display: none;
          }
        }

        .special-discount-container {
          max-width: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          img {
            width: 100%;
            height: 80px;
            object-fit: contain;
          }
        }

        .price-with-tooltip {
          display: flex;
          position: relative;

          .price-container {
            &.with-toottip {
              opacity: 0.3;
              width: 100%;
            }
          }
        }

        .tooltip-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          line-height: 1rem;
        }
      }

      .price-with-tooltip{
        display: flex;
        justify-content: space-between;
      }

      .price-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        &.hidden{
          visibility: hidden;
        }    
        .price{
          flex-direction: column;
          align-items: start !important;
          gap: 0 !important;
          
          }
        }
        .old-price{
          position: relative;
          top: -10px;
          display: flex;
          align-items: center;
          gap: 8px;
            & > p{
              padding-top: 2px;
            }
      }

      .full-price-container {
        display: flex;
        flex-direction: column;
        align-items: end;    
        .price{
          flex-direction: column;
          align-items: end !important;
          gap: 0 !important;
        }
        .old-price{
          position: relative;
          top: -10px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .old-price{
        position: relative;
        top: -10px;
        display: flex;
        align-items: center;
        gap: 8px;
      }


      .price-container {
        .price-visible {
          visibility: hidden;
        }

        .price {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .data-icons {
        &__container {
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
          margin: 12px 0;
        }

        &__item {
          display: flex;
          align-items: center;
          gap: 12px;
          width: calc(50% - 6px);
        }
      }

      .auction-time-bar {
        margin-top: 26px;
      }
    }

    .right-wing {
      width: 40%;

      .upper-container {
        & > p {
          margin-bottom: 4px;
        }
      }

      .price-brutto-container {
        display: flex;
      }

      .lower-container {
        margin-top: 24px;
      }
    }
  }
}

.car-offer-tile--sold {
  cursor: auto;
  .car-image,
  .upper-container,
  .lower-container,
  .car-informations-container {
    opacity: 0.4;
    cursor: default;
  }
  .sold-car-buttons__top {
    cursor: default;
  }
}

@media screen and (max-width: 1023px) {
  .car-offer-tile {
    width: 100%;

    .car-image-container {
      height: 176px;
      border-radius: 24px 24px 0 0;

      .sold-car-buttons {
        .sold-car-buttons__top {
          top: 32;
        }

        .sold-car-buttons__bottom {
          justify-content: space-between;
        }
      }

      .icon {
        top: 28px;
        right: 28px;
      }
    }

    .car-informations-container {
      padding: 24px;

      .left-wing {
        &.decreased-width {
          width: 100%;
        }

        .all-prices-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 24px;
          & > div {
            min-width: 90px;
          }

          @media screen and (max-width: 520px) {
            & > *:nth-child(4) {
              display: none;
            }
          }

          .price-container {
            width: auto;

            &:not(:last-of-type) {
              margin-right: 0;
            }

            .price {
              flex-wrap: wrap;
            }
          }

          .price-with-tooltip {
            margin-right: unset;
            position: relative;
          }
        }

        .auction-time-bar {
          margin-top: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .car-offer-tile {
    .car-informations-container {
      padding: 16px;
    }
  }
}
