@import "../../../assets/styles/variables.scss";

.search-menu {
  position: fixed;
  z-index: 890; // no variable because of ds-component properties
  width: 100%;
  background-color: #fff;
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__shadow {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 889;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  &__container {
    max-height: 100vh;
    overflow-y: scroll;
    display: flex;
    gap: 40px;
    padding: 16px 146px 41px;
    justify-content: space-between;
    background: white;
    margin: auto;
    max-width: 1600px;
  }

  &__left-wing {
    .logo {
      padding-bottom: 16px;
    }
  }

  &__top {
    display: flex;
    align-items: center;

    .close-btn {
      cursor: pointer;
      margin-left: 16px;
      margin-top: 8px;
    }

    .autocomplete-container {
      padding-bottom: 16px;
      width: 100%;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contact-card {
      align-self: flex-start;
    }
    .search-menu-contact-card {
      margin-top: 31px;
      margin-bottom: 41px;
      width: 343px;
    }

    .tabs {
      width: 100%;
      max-width: 658px;
      margin-right: 32px;

      .tab {
        &__label {
          display: flex;
          justify-content: space-between;
          padding: 24px 0 12px 0;
          border-bottom: 1px #d8d8d8 solid;

          &--blue {
            color: #0065b1;
            cursor: pointer;
          }
        }

        &__item {
          display: flex;
          align-items: center;
          gap: 16px;

          a {
            width: 100%;
            padding: 12px 0;
            border-bottom: 1px #d8d8d8 solid;
            color: #26221e;
          }
        }
        &__item:last-child {
          a {
            border-bottom: none;
          }
        }
      }
    }
  }
}
