.exit-popup-container {
    position: fixed;
    z-index: 17;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    .exit-popup {
      position: relative;
      width: 644px;
      height: 448px;
      overflow: hidden;
      padding: 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      &__close-button {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 4px;
        cursor: pointer;
      }
      &__text-container {
        z-index: 1;
        width: 60%;
        h2 {
          font-size: 30px;
        }
        p {
          font-size: 22px;
        }
        span {
          font-weight: 600;
        }
      }
      &__illustration-container {
        position: absolute;
        right: -50px;
      }
    }
  }
  @media (max-width: 768px) {
    .exit-popup-container {
      display: none;
    }
  }