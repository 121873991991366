.horizontal-radiobox-container {
  height: auto;

  &.dark {
    .radiobox-container {
      border-color: #6e6e6e;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
  }

  .radiobox-container {
    display: flex;
    align-items: center;

    border-radius: 24px;
    border-width: 1.5px;
    border-color: #d8d8d8;
    border-style: solid;
    width: 100%;

    .radio-option {
      height: 32px;

      input[type="radio"]:checked + label {
        background-color: #0065b1;
        color: white;
        border-radius: 24px;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: center;
        padding: 4px;
      }

      label {
        padding: 8px;
        min-width: 77px;
        width: 100%;
        display: inline-block;
        border-radius: 24px;
        border: none;
        cursor: pointer;
        height: 100%;

        // &.long {
        //   min-width: 130px;
        // }

        // &.normal {
        //   min-width: 40px;
        // }

        // &.small {
        //   min-width: 70px;
        // }
      }

      input {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .radiobox-container .radio-option label {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .horizontal-radiobox-container .radiobox-container .radio-option label {
    min-width: 67px;
  }
}
