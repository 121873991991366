.contact-form-details-section-container {
  padding-bottom: 16px;

  .section-title {
    margin-bottom: 54px;
    text-align: center;
  }

  //.message-box {
  //  margin: 10px 0 20px;
  //}

  .text-field-container {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    & > div[data-component="Stack"] {
      width: 100%;
      & > div {
        & > div {
          width: calc(50% - 24px)
        }
      }
    }
  }

  .contact-form-details-header {
    padding-bottom: 15px;

    .icon-container {
      margin-bottom: 8px;

      svg {
        width: 48px;
        height: 48px;
      }
    }

    .title {
      margin-bottom: 8px;
    }

    .description {
      margin-bottom: 24px;
    }
  }

  form {
    input {
      width: 100%;
    }

    & > div[data-component="Checkbox"] {
      margin-bottom: 16px;
    }

    & > div[data-component="TextField"] {
      margin: 10px 0 20px;
    }
  }

  .submit-status-container {
    margin-top: 24px;
    height: 24px;
  }

  .buttons-container {
    margin: 48px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .trade-information {
    padding-bottom: 48px;
  }

  &.details {
    .contact-form-details-header {
      padding-bottom: 32px;
      padding-top: 80px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1023px) {
  .contact-form-details-section-container {
    padding: 20px 16px;

    .buttons-container {
      display: flex;
      justify-content: center;

      button {
        display: inline;
        width: calc(80% + 32px);
      }
    }

    .text-field-container {
      flex-direction: column;

      & > div[data-component="Stack"] {
        width: 100%;
        & > div {
          & > div {
            width: auto;
          }
        }
      }

      //& > div[data-component="Stack"] {
      //  flex-direction: column;
      //
      //  & > div:first-of-type {
      //    margin-bottom: 20px;
      //  }
      //}

      .form-input, input {
        width: 100%;
      }
    }
  }
}
