.advise-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 305px;
  margin-top: 48px;

  &.padding-less {
    .mleasing-main-container {
      padding: 0;
    }
  }

  .mleasing-main-container {
    padding: 0 136px;
    width: 100%;

    .wrapper {
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      padding: 20px 0;

      .content {
        display: flex;
        justify-content: space-between;
        max-width: 785px;

        .illustration-container {
          min-width: 255px;
          width: 30%;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 60%;

          .icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            background-color: #0065b1;
            border-radius: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .advise-section-container {
    .mleasing-main-container {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .advise-section-container {
    height: auto;

    .mleasing-main-container {
      .wrapper {
        .content {
          flex-direction: column;

          .illustration-container,
          .text-container {
            width: 100%;
          }

          .illustration-container {
            display: flex;
            justify-content: center;
          }

          .text-container {
            padding: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}
