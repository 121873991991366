.section-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 642px;
  text-align: center;

  .title-and-icon {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    svg {
      margin-right: 20px;
      width: 32px;
      height: 32px;
    }
  }
}
@media (max-width: 1023px) {
  .section-title-container {
    padding: 0 16px;

    .title-and-icon {
      flex-direction: column;
      margin-bottom: 8px;
      font-size: 24px;

      svg {
        margin-bottom: 5px;
        margin-right: 0;
      }
    }
  }
}
